import { firestore } from "./firebase";
import { log } from "./utils";

import type firebase from "firebase";

type DocumentChange = firebase.firestore.DocumentChange;
type QuerySnapshot = firebase.firestore.QuerySnapshot;

import { Account } from "./wholesaleAccount";

const db = firestore();

function getCurrentTotal(a: Account) {
	return (a._counter?.orders.open || 0) - (a._counter?.orders.completed || 0);
}

export function watch(accounts: Account[]): void {
	db.collection("accounts").onSnapshot((snapshot: QuerySnapshot) => {
		snapshot.docChanges().forEach((change: DocumentChange) => {
			const doc = change.doc.data() as Account;
			doc.id = change.doc.id;
			const index = accounts.findIndex((a: Account) => a.id === doc.id);
			const shouldAddAddition =
				change.type === "added" &&
				index === -1 &&
				doc.disabled === undefined;
			const shouldAddUpdate =
				change.type === "modified" &&
				doc.disabled === undefined &&
				index < 0;
			const shouldRemoveRemoval = change.type === "removed";
			const shouldRemoveUpdate =
				change.type === "modified" && doc.disabled === true;
			if (shouldAddAddition || shouldAddUpdate) {
				accounts.push(doc);
			}
			if (shouldRemoveRemoval || shouldRemoveUpdate) {
				accounts.splice(index, 1);
			}
		});
		accounts.sort((a, b) =>
			getCurrentTotal(a) > getCurrentTotal(b) ? -1 : 1,
		);
	}, log.info);
}
