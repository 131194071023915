import Vue from "vue";

interface Component extends Vue {
	active: boolean;
}

function toggleMenu(this: Component, event: MouseEvent): void {
	event.stopPropagation();
	this.active = !this.active;

	const dismissMenu = ((): void => {
		this.active = false;
		removeEventListener("click", dismissMenu);
	}).bind(this) as () => void;

	if (this.active) {
		addEventListener("click", dismissMenu);
	}
}

export default Vue.component("app-popup-menu", {
	data(): object {
		return { active: false };
	},
	methods: { toggleMenu },
	template: `
	<div class=app-popup-menu>
		<div class=app-popup-menu__trigger @click=toggleMenu>
			<i class=app-popup-menu__trigger__dot></i>
			<i class=app-popup-menu__trigger__dot></i>
			<i class=app-popup-menu__trigger__dot></i>
		</div>
		<transition name=fade>
			<div class=app-popup-menu__links v-show=active>
				<slot></slot>
			</div>
		</transition>
	</div>
	`,
});
