import { Popover, Transition } from "@headlessui/react";
import { CalendarDaysIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import {
	endOfDay,
	endOfMonth,
	endOfWeek,
	endOfYear,
	format,
	isSameDay,
	startOfMonth,
	startOfWeek,
	startOfYear,
	subMonths,
	subWeeks,
	subYears,
} from "date-fns";
import { enGB } from "date-fns/locale";
import { Fragment, useEffect, useState } from "react";
import {
	createStaticRanges,
	DateRangePicker,
	Range,
	StaticRange,
} from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useSearchParams } from "react-router-dom";

type Props = {
	className?: string;
	onChange(date: [Date, Date]): void;
};

const today = new Date();

function isSelected(this: StaticRange, range: Range) {
	const { startDate, endDate } = this.range();
	return (
		isSameDay(startDate!, range.startDate!) &&
		isSameDay(endDate!, range.endDate!)
	);
}

const staticRanges = createStaticRanges([
	{
		label: "This week",
		isSelected,
		range: () => ({
			startDate: startOfWeek(today, { weekStartsOn: 1 }),
			endDate: endOfDay(today),
		}),
	},
	{
		label: "Last week",
		isSelected,
		range: () => ({
			startDate: startOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }),
			endDate: endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }),
		}),
	},
	{
		label: "This month",
		isSelected,
		range: () => ({
			startDate: startOfMonth(today),
			endDate: endOfDay(today),
		}),
	},
	{
		label: "Last month",
		isSelected,
		range: () => ({
			startDate: startOfMonth(subMonths(today, 1)),
			endDate: endOfMonth(subMonths(today, 1)),
		}),
	},
	{
		label: "This year",
		isSelected,
		range: () => ({
			startDate: startOfYear(today),
			endDate: endOfDay(today),
		}),
	},
	{
		label: "Last year",
		isSelected,
		range: () => ({
			startDate: startOfYear(subYears(today, 1)),
			endDate: endOfYear(subYears(today, 1)),
		}),
	},
]);

const DateRange: React.FC<Props> = (props) => {
	const [params] = useSearchParams();
	const [range, setRange] = useState<Range>();

	useEffect(() => {
		const from = params.get("from")?.toString();
		const to = params.get("to")?.toString();
		if (from && to) {
			setRange({
				startDate: from ? new Date(from) : new Date(),
				endDate: to ? new Date(to) : new Date(),
				key: "selection",
			});
		}
	}, [params]);

	return (
		<Popover className="absolute right-8 top-8">
			<>
				<Popover.Button className="flex gap-2">
					<h4 className="flex items-center gap-1 text-xs text-gray-500">
						{range && format(range.startDate!, "LLL d, y")}
						<ChevronRightIcon className="h-3 w-3" />
						{range && format(range.endDate!, "LLL d, y")}
					</h4>
					<CalendarDaysIcon className="h-6 w-6" />
				</Popover.Button>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-200"
					enterFrom="opacity-0 translate-y-1"
					enterTo="opacity-100 translate-y-0"
					leave="transition ease-in duration-150"
					leaveFrom="opacity-100 translate-y-0"
					leaveTo="opacity-0 translate-y-1"
				>
					<Popover.Panel className="absolute right-0 ">
						<DateRangePicker
							locale={enGB}
							className={props.className}
							ranges={range ? [range] : []}
							staticRanges={staticRanges}
							inputRanges={[]}
							weekStartsOn={1}
							maxDate={endOfDay(today)}
							onChange={({ selection }) => {
								if (
									!selection?.startDate ||
									!selection?.endDate
								)
									return;
								setRange(selection);
								const { startDate, endDate } = selection;
								//if (!isSameDay(startDate, endDate))
								props.onChange([startDate, endDate]);
							}}
						/>
					</Popover.Panel>
				</Transition>
			</>
		</Popover>
	);
};

export default DateRange;
