import { Popover } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import {
	fmtOrderRef,
	getVoucherStatus,
	tsToDate,
	voucherCurrencies,
	VoucherRaw,
} from "@launerlondon/shared";
import cx from "classnames";
import { Suspense } from "react";
import {
	Await,
	Form,
	Link,
	Outlet,
	useAsyncValue,
	useLoaderData,
} from "react-router-dom";

const Item: React.FC<{ item: VoucherRaw }> = ({ item }) => {
	const date = tsToDate(item.createdAt).toLocaleDateString();
	const order = item.events[0].order;
	const lastEvent = item.events[item.events.length - 1];
	const lastDate =
		lastEvent && lastEvent.type !== "create" ? lastEvent.date : undefined;
	const status = getVoucherStatus(item);
	return (
		<tr>
			<td className="text-left">
				<Link
					className="text-blue-500 underline"
					to={`/vouchers/${item.id}`}
				>
					###-{item.code.split("-").pop()}
				</Link>
			</td>
			<td className="text-left">
				<div
					className={cx(
						status === "active"
							? "bg-green-100 text-green-500"
							: "bg-gray-100 text-gray-500",
						"rounded-full px-2 py-1 text-center text-xs font-bold",
					)}
				>
					{status}
				</div>
			</td>
			<td>
				{item.amount}/{item.initialAmount}
			</td>
			<td>{item.currency}</td>
			<td>
				{order ? (
					<Link
						reloadDocument
						to={`/retail/orders/${order.id}/${order.ref}`}
						className="text-blue-500 underline"
					>
						{fmtOrderRef(order.ref)}
					</Link>
				) : (
					"-"
				)}
			</td>
			<td className="text-right">{date}</td>
			<td className="text-right">
				{lastDate ? tsToDate(lastDate).toLocaleDateString() : "-"}
			</td>
		</tr>
	);
};

const NewItemButton: React.FC = () => {
	return (
		<Popover>
			<Popover.Button
				className={cx(
					"absolute right-8 top-5 flex items-center",
					"text-sm",
					"gap-1 rounded bg-gray-100 p-2",
				)}
			>
				New Voucher
				<PlusIcon className="h-4 w-4" />
			</Popover.Button>
			<Popover.Panel className="absolute inset-0 flex items-center justify-center">
				{({ close }) => (
					<>
						<Popover.Overlay className="fixed inset-0 bg-black/50" />
						<Form
							className="relative flex max-h-[80vh] w-full max-w-md flex-col gap-4 rounded bg-white p-4 shadow"
							method="post"
							onSubmit={() => close()}
						>
							<h3 className="text-center text-lg font-bold">
								Create a voucher
							</h3>
							<label>
								<span className="text-sm">Currency</span>
								<select
									name="currency"
									className="block w-full rounded bg-gray-100 p-2"
								>
									{voucherCurrencies.map((c) => (
										<option key={c}>{c}</option>
									))}
								</select>
							</label>
							<label>
								<span className="text-sm">Amount</span>
								<input
									type="number"
									name="amount"
									step={10}
									defaultValue={500}
									required
									className="block w-full rounded bg-gray-100 p-2"
								/>
							</label>
							<button className="mt-4 rounded bg-black p-2 text-sm font-light uppercase tracking-widest text-white">
								Create Voucher
							</button>
						</Form>
					</>
				)}
			</Popover.Panel>
		</Popover>
	);
};

const Items: React.FC = () => {
	const items = useAsyncValue() as VoucherRaw[];
	return (
		<div>
			<NewItemButton />
			<table className="w-full table-fixed" cellPadding={4}>
				<thead className="rounded bg-gray-100 text-right text-sm">
					<tr>
						<th className="text-left">Code</th>
						<th className="text-left">Status</th>
						<th>Amount</th>
						<th>Currency</th>
						<th>Parent Order</th>
						<th className="text-right">Created At</th>
						<th className="text-right">Last Used</th>
					</tr>
				</thead>
				<tbody className="divide-y text-right">
					{items.map((i) => (
						<Item key={i.id} item={i} />
					))}
				</tbody>
			</table>
			<Outlet />
		</div>
	);
};

const VoucherList: React.FC = () => {
	const data = useLoaderData() as { items: Promise<VoucherRaw[]> };
	return (
		<div>
			<Suspense fallback="loading…">
				<Await resolve={data.items} children={<Items />} />
			</Suspense>
		</div>
	);
};

export default VoucherList;
