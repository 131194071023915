import Vue from "vue";
import { log } from "../lib/utils";

// @types
import { Account, AccountAddress } from "../lib/wholesaleAccount";

interface Component extends Vue {
	account: Account;
	address: AccountAddress;
}

function reset(this: Vue): void {
	const initialData = this.$options.data as () => object;
	Object.assign(this.$data, initialData());
}

function addAddressToAccount(this: Component, event: MouseEvent): void {
	event.preventDefault();
	if (this.account.addresses === undefined) {
		log.info("CREATING NEW ADDRESSES ARRAY");
		this.account.addresses = [];
	}
	this.account.addresses.push({ ...this.address });
	reset.call(this);
}

export default Vue.component("account-address-new", {
	data(): object {
		return {
			address: {
				country: "United Kingdom",
			},
		};
	},
	methods: { addAddressToAccount },
	props: ["account"],
	template: `
	<form class='account-address-new account-edit__fieldset' @submit=addAddressToAccount>
		<label class=app-field>
			<span class=app-field__label>Address line 1</span>
			<input class=app-field__input v-model=address.line1 required>
		</label>
		<label class=app-field>
			<span class=app-field__label>Address line 2</span>
			<input class=app-field__input v-model=address.line2>
		</label>
		<label class=app-field>
			<span class=app-field__label>Town / City</span>
			<input class=app-field__input v-model=address.city placeholder=London required>
		</label>
		<label class=app-field>
			<span class=app-field__label>County / Region</span>
			<input class=app-field__input v-model=address.region>
		</label>
		<label class=app-field>
			<span class=app-field__label>Country</span>
			<input class=app-field__input v-model=address.country required>
		</label>
		<label class=app-field>
			<span class=app-field__label>Postcode</span>
			<input class=app-field__input v-model=address.postcode placeholder='W1F 9EH'>
		</label>

		<button class='app-button app-button_center' type=submit>Add address</button>
	</form>
	`,
});
