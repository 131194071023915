import { Order } from "@launerlondon/shared";
import Vue from "vue";
import { fmtCurrency } from "../lib/utils";

// @types
import { orderTotal, orderTotalItemsStep } from "../lib/wholesaleOrder";

function getOrderColor(order: Order) {
	if (order.completionDate) return "bg-emerald-100";
	const dueDate = order.dueDate?.toDate() || new Date();
	if (new Date() > dueDate) return "bg-rose-100";
	return "bg-amber-100";
}

export default Vue.component("order-card", {
	methods: {
		fmtCurrency,
		orderTotal,
		orderTotalItemsStep,
		getOrderColor,
	},
	props: ["order"],
	template: `
	<router-link
		class='flex bg-gray-100 justify-between rounded divide-x [&>dl]:flex-1 [&>dl]:p-4 [&>dl>dt]:text-gray-400 [&>dl>dt]:text-xs overflow-hidden [&>dl>dd]:tracking-wider'
		:to="{name:'orderView', params:{accountId:$route.params.accountId, orderId:order.id}}">
			<dl>
				<dt>P.O. Number</dt>
				<dd class='whitespace-nowrap'>{{order.reference || '–'}}</dd>
			</dl>
			<dl>
				<dt>Order Date</dt>
				<dd>{{order.date.toDate().toLocaleDateString()}}</dd>
			</dl>
			<dl>
				<dt>Order Value</dt>
				<dd>£{{fmtCurrency(orderTotal(order))}}</dd>
			</dl>
			<dl>
				<dt>Total items</dt>
				<dd>{{order.items.length}}</dd>
			</dl>
			<dl :class=getOrderColor(order)>
				<dt>Cut</dt>
				<dd>{{orderTotalItemsStep('cut', order)}}</dd>
			</dl>
			<dl :class=getOrderColor(order)>
				<dt>Prepare</dt>
				<dd>{{orderTotalItemsStep('prepare', order)}}</dd>
			</dl>
			<dl :class=getOrderColor(order)>
				<dt>Complete</dt>
				<dd>{{orderTotalItemsStep('complete', order)}}</dd>
			</dl>
	</router-link>
	`,
});
