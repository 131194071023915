import Vue from "vue";

const computed = {
	activeFirstLink(this: Vue): string {
		const routeName = this.$route.name;
		if (routeName === "accountList" || routeName === "retailOrderList") {
			return "app-breadcrumb__item_active";
		}

		return "";
	},
};

export default Vue.component("app-breadcrumb", {
	computed,
	methods: {},
	props: {
		account: Object,
		section: { default: "", type: String },
		sectionPath: { default: "/", type: String },
		title: String,
	},
	template: `
	<nav class=app-breadcrumb>
		<router-link :class="'app-breadcrumb__item ' + activeFirstLink" :to=sectionPath>{{section}}</router-link>
		<router-link :class="'app-breadcrumb__item ' + (title ? '' : 'app-breadcrumb__item_active')" :to="'/accounts/' + account.id">{{account.name}}</router-link>
		<span v-show=title class='app-breadcrumb__item app-breadcrumb__item_active'>{{title}}</span>
	</nav>
	`,
});
