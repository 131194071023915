const prices = {
	"010-01": [127, 41],
	"011-01": [66, 21],
	"103-02": [610, 197],
	"103-03": [610, 197],
	"112-01": [370, 119],
	"201-01": [415, 134],
	"202-01": [420, 135],
	"203-01": [430, 139],
	"204-01": [415, 134],
	"205-01": [330, 106],
	"205-02": [330, 106],
	"205-03": [330, 106],
	"205-04": [330, 106],
	"205-05": [330, 106],
	"206-01": [270, 87],
	"206-02": [270, 87],
	"228-01": [480, 155],
	"228-02": [480, 155],
	"232-01": [175, 56],
	"232-02": [175, 56],
	"232-03": [175, 56],
	"232-04": [175, 56],
	"232-05": [175, 56],
	"232-06": [175, 56],
	"239-01": [255, 82],
	"244-01": [138, 45],
	"244-02": [138, 45],
	"244-03": [138, 45],
	"244-04": [138, 45],
	"244-06": [138, 45],
	"244-07": [138, 45],
	"245-01": [253, 82],
	"245-02": [253, 82],
	"254-01": [540, 174],
	"254-02": [540, 174],
	"254-03": [540, 174],
	"254-04": [540, 174],
	"254-05": [540, 174],
	"254-06": [540, 174],
	"254-07": [540, 174],
	"254-08": [540, 174],
	"268-02": [620, 200],
	"268-03": [620, 200],
	"268-04": [620, 200],
	"268-05": [620, 200],
	"268-06": [745, 240],
	"268-07": [745, 240],
	"399-01": [380, 123],
	"399-02": [380, 123],
	"477-01": [190, 61],
	"477-02": [190, 61],
	"478-01": [210, 68],
	"486-01": [370, 119],
	"486-02": [370, 119],
	"496-01": [143, 46],
	"496-02": [143, 46],
	"496-03": [143, 46],
	"496-04": [143, 46],
	"496-05": [143, 46],
	"496-06": [143, 46],
	"496-07": [143, 46],
	"496-08": [143, 46],
	"496-09": [143, 46],
	"501-01": [176, 57],
	"517-01": [132, 43],
	"517-02": [132, 43],
	"610-01": [330, 106],
	"610-02": [330, 106],
	"610-03": [330, 106],
	"620-01": [450, 145],
	"620-02": [450, 145],
	"620-03": [450, 145],
	"659-01": [450, 145],
	"659-02": [450, 145],
	"670-01": [270, 87],
	"670-02": [270, 87],
	"670-03": [270, 87],
	"670-04": [270, 87],
	"685-01": [400, 129],
	"685-02": [400, 129],
	"685-03": [820, 265],
	"685-04": [400, 129],
	"685-05": [400, 129],
	"685-06": [400, 129],
	"685-07": [400, 129],
	"685-08": [400, 129],
	"685-09": [400, 129],
	"685-10": [400, 129],
	"685-11": [400, 129],
	"685-12": [400, 129],
	"685-13": [400, 129],
	"685-14": [400, 129],
	"685-15": [400, 129],
	"685-16": [530, 171],
	"685-17": [530, 171],
	"685-18": [400, 129],
	"685-19": [400, 129],
	"686-03": [510, 165],
	"686-04": [510, 165],
	"686-06": [510, 165],
	"686-07": [510, 165],
	"686-08": [510, 165],
	"686-09": [510, 165],
	"686-10": [510, 165],
	"686-11": [510, 165],
	"686-12": [510, 165],
	"686-13": [510, 165],
	"686-14": [510, 165],
	"686-15": [510, 165],
	"686-16": [510, 165],
	"686-17": [510, 165],
	"686-18": [510, 165],
	"686-19": [510, 165],
	"686-20": [510, 165],
	"717-01": [375, 121],
	"717-03": [375, 121],
	"717-04": [375, 121],
	"717-05": [375, 121],
	"717-06": [375, 121],
	"717-07": [375, 121],
	"717-08": [375, 121],
	"717-09": [375, 121],
	"717-10": [375, 121],
	"717-11": [375, 121],
	"717-12": [375, 121],
	"717-13": [375, 121],
	"717-14": [375, 121],
	"726-01": [204, 66],
	"726-02": [204, 66],
	"726-03": [204, 66],
	"726-04": [204, 66],
	"726-05": [204, 66],
	"726-06": [204, 66],
	"738-02": [610, 197],
	"738-03": [610, 197],
	"738-04": [610, 197],
	"738-05": [610, 197],
	"738-06": [610, 197],
	"738-07": [610, 197],
	"738-08": [610, 197],
	"738-09": [610, 197],
	"746-01": [143, 46],
	"746-02": [143, 46],
	"746-03": [143, 46],
	"746-04": [143, 46],
	"746-05": [143, 46],
	"746-06": [180, 58],
	"746-07": [143, 46],
	"746-08": [143, 46],
	"746-09": [143, 46],
	"746-10": [143, 46],
	"759-01": [578, 186],
	"785-01": [160, 52],
	"785-02": [160, 52],
	"785-03": [160, 52],
	"785-04": [160, 52],
	"785-05": [160, 52],
	"785-06": [160, 52],
	"785-07": [160, 52],
	"785-08": [160, 52],
	"785-09": [160, 52],
	"785-10": [160, 52],
	"794-01": [410, 132],
	"794-02": [410, 132],
	"805-01": [330, 106],
	"805-04": [330, 106],
	"805-05": [330, 106],
	"805-11": [330, 106],
	"805-13": [330, 106],
	"805-14": [330, 106],
	"805-15": [330, 106],
	"805-17": [330, 106],
	"805-18": [650, 210],
	"805-22": [330, 106],
	"805-24": [330, 106],
	"805-25": [330, 106],
	"805-26": [330, 106],
	"805-28": [330, 106],
	"805-29": [330, 106],
	"805-30": [330, 106],
	"805-31": [330, 106],
	"805-32": [410, 132],
	"805-33": [330, 106],
	"805-34": [330, 106],
	"806-01": [255, 82],
	"806-02": [255, 82],
	"806-03": [255, 82],
	"806-04": [255, 82],
	"806-05": [305, 98],
	"806-06": [255, 82],
	"806-07": [255, 82],
	"872-01": [495, 160],
	"874-01": [165, 53],
	"874-02": [165, 53],
	"874-03": [165, 53],
	"874-04": [165, 53],
	"874-05": [165, 53],
	"874-06": [165, 53],
	"874-07": [165, 53],
	"874-08": [165, 53],
	"874-09": [165, 53],
	"874-10": [165, 53],
	"874-11": [165, 53],
	"874-12": [165, 53],
	"882-01": [230, 74],
	"882-02": [230, 74],
	"882-03": [230, 74],
	"882-04": [230, 74],
	"882-05": [230, 74],
	"882-06": [230, 74],
	"882-07": [230, 74],
	"882-08": [230, 74],
	"882-09": [230, 74],
	"882-10": [230, 74],
	"920-01": [176, 57],
	"920-02": [176, 57],
	"925-01": [220, 71],
	"925-02": [220, 71],
	"925-03": [220, 71],
	"938-01": [204, 66],
	"938-02": [204, 66],
	"938-03": [204, 66],
	"938-04": [204, 66],
	"938-05": [204, 66],
	"975-01": [300, 97],
	"975-02": [300, 97],
	"2663-01": [1540, 497],
	"2666-01": [1480, 477],
	"2666-02": [1480, 477],
	"2666-03": [1480, 477],
	"2666-04": [1480, 477],
	"2666-05": [1480, 477],
	"2826-01": [3080, 994],
	"2826-02": [3080, 994],
	"2826-04": [3080, 994],
	"2826-05": [3080, 994],
	"2875-01": [2270, 732],
	"2875-02": [2270, 732],
	"2875-03": [2270, 732],
	"2875-04": [2270, 732],
	"2900-03": [2750, 887],
	"2900-04": [2750, 887],
	"2907-01": [2640, 852],
	"2907-02": [2640, 852],
	"2910-01": [1590, 513],
	"2910-03": [1590, 513],
	"2910-04": [1590, 513],
	"2910-05": [1980, 639],
	"2910-06": [1980, 639],
	"2910-07": [1590, 513],
	"2910-08": [1590, 513],
	"2914-01": [2640, 852],
	"2914-02": [2640, 852],
	"2914-03": [2640, 852],
	"2914-05": [2640, 852],
	"2914-07": [2640, 852],
	"2914-08": [2640, 852],
	"2914-09": [2640, 852],
	"2914-10": [2640, 852],
	"2914-11": [2640, 852],
	"2914-12": [2640, 852],
	"2914-13": [2640, 852],
	"2914-14": [2640, 852],
	"2914-15": [2640, 852],
	"2914-16": [2640, 852],
	"2941-01": [3410, 1100],
	"2941-02": [3410, 1100],
	"2947-01": [2640, 852],
	"2947-02": [2640, 852],
	"2947-03": [2640, 852],
	"2947-04": [2640, 852],
	"2947-05": [2640, 852],
	"2947-06": [2640, 852],
	"2947-07": [2640, 852],
	"2947-08": [2640, 852],
	"3009-01": [1100, 355],
	"3011-01": [2640, 852],
	"3011-02": [2640, 852],
	"3011-03": [2640, 852],
	"3011-04": [2640, 852],
	"3011-06": [2640, 852],
	"3011-07": [2640, 852],
	"3013-01": [2640, 852],
	"3013-02": [2640, 852],
	"3013-04": [2640, 852],
	"3013-05": [2640, 852],
	"3013-06": [2640, 852],
	"3046-01": [1980, 639],
	"3050-01": [2640, 852],
	"3050-03": [2640, 852],
	"3050-04": [2640, 852],
	"3050-06": [2640, 852],
	"3050-07": [8030, 2590],
	"3050-09": [2640, 852],
	"3050-10": [2640, 852],
	"3050-14": [2640, 852],
	"3050-15": [8250, 2661],
	"3050-18": [2640, 852],
	"3050-20": [2640, 852],
	"3050-22": [2640, 852],
	"3050-23": [3480, 1123],
	"3050-24": [2640, 852],
	"3050-26": [2640, 852],
	"3050-28": [2640, 852],
	"3050-29": [2640, 852],
	"3050-30": [2640, 852],
	"3050-31": [3190, 1123],
	"3050-32": [2640, 852],
	"3050-33": [2640, 852],
	"3050-34": [2640, 852],
	"3051-01": [1760, 568],
	"3051-03": [1760, 568],
	"3051-04": [1760, 568],
	"3051-05": [1760, 568],
	"3051-06": [1760, 568],
	"3051-07": [1760, 568],
	"3064-01": [2530, 816],
	"3064-02": [2530, 816],
	"3064-03": [2530, 816],
	"3064-04": [2530, 816],
	"3065-01": [2420, 781],
	"3065-02": [2420, 781],
	"3065-03": [2420, 781],
	"3085-01": [1980, 639],
	"3085-02": [1980, 639],
	"3085-03": [1980, 639],
	"3085-04": [1980, 639],
	"3086-02": [1980, 639],
	"3086-03": [1980, 639],
	"3087-02": [1980, 639],
	"3087-03": [1980, 639],
	"3087-04": [1980, 639],
	"3087-05": [1980, 639],
	"3087-06": [1980, 639],
	"3087-07": [1980, 639],
	"3087-08": [1980, 639],
	"3087-09": [1980, 639],
	"3092-01": [2860, 923],
	"3092-02": [2860, 923],
	"3093-01": [1270, 410],
	"3093-02": [1270, 410],
	"3093-03": [1270, 410],
	"3097-03": [2200, 710],
	"3097-05": [2200, 710],
	"3097-07": [2200, 710],
	"3097-08": [2200, 710],
	"3097-10": [2200, 710],
	"3097-11": [2200, 710],
	"3097-14": [2200, 710],
	"3097-15": [2200, 710],
	"3097-17": [2200, 710],
	"3097-18": [2200, 710],
	"3097-19": [2530, 890],
	"3097-20": [2530, 890],
	"3097-21": [2200, 710],
	"3097-22": [2200, 710],
	"3097-23": [2760, 890],
	"3097-24": [2200, 710],
	"3098-01": [3190, 1029],
	"3100-01": [1760, 568],
	"3100-03": [1760, 568],
	"3103-01": [3190, 1029],
	"3103-02": [3190, 1029],
	"3103-05": [3190, 1029],
	"3103-06": [3190, 1029],
	"3104-01": [1650, 532],
	"3106-02": [2750, 887],
	"3107-02": [2750, 887],
	"3108-01": [3190, 1029],
	"3108-03": [3190, 1029],
	"3108-04": [3190, 1029],
	"3108-05": [3190, 1029],
	"3110-01": [2150, 694],
	"3110-02": [2150, 694],
	"3110-03": [2150, 694],
	"3110-04": [2150, 694],
	"3111-01": [3020, 974],
	"3111-02": [3020, 974],
	"3111-03": [3020, 974],
	"3113-01": [1870, 603],
	"3113-02": [1870, 603],
	"3113-03": [1870, 603],
	"3113-04": [1870, 603],
	"3113-06": [1870, 603],
	"3113-07": [1870, 603],
	"3113-08": [1870, 603],
	"3113-09": [1870, 603],
	"3117-01": [2640, 852],
	"3118-01": [1870, 532],
	"3118-02": [1870, 532],
	"3119-01": [1430, 461],
	"3119-02": [1430, 461],
	"3119-03": [1430, 461],
	"3119-04": [1430, 461],
	"3119-05": [1430, 461],
	"3121-01": [1870, 603],
	"3124-01": [2200, 710],
	"3124-02": [2200, 710],
	"3124-03": [2200, 710],
	"3124-04": [2200, 710],
	"3124-05": [2200, 710],
	"3124-06": [2200, 710],
	"3124-07": [2200, 710],
	"3124-08": [2760, 890],
	"3124-09": [2200, 710],
	"3124-10": [2200, 710],
	"3126-01": [2200, 710],
	"3126-02": [2200, 710],
	"3126-03": [2200, 710],
	"3126-04": [2200, 710],
	"3126-05": [2200, 710],
	"3126-06": [2200, 710],
	"3126-07": [2200, 710],
	"3127-02": [3190, 1029],
	"3127-03": [3190, 1029],
	"3127-05": [3190, 1029],
	"3127-06": [3190, 1029],
	"3127-08": [3190, 1029],
	"3128-01": [2640, 852],
	"3129-01": [1160, 374],
	"3137-01": [2860, 923],
	"3138-01": [3190, 1029],
	"3130-01": [0, 645],
	"3028-01": [0, 532],
	"3099-01": [0, 548],
	"0002-01": [80, 26],
	"0002-02": [150, 48],
	"0003-01": [60, 19],
	"0003-02": [80, 26],
	"0004-01": [35, 11],
	"0005-01": [160, 52],
} as Record<string, [number, number]>;

export function getPrice(sku: string) {
	return prices[sku] || [0, 0];
}
