import { LocalizationProvider } from "@launerlondon/l10n";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Outlet, useNavigate } from "react-router-dom";

declare global {
	interface Window {
		__nav?: (to: string) => void;
	}
}

const AppLayout: React.FC = () => {
	const nav = useNavigate();
	// expose nav function to allow vue router control
	useEffect(() => {
		self.__nav = nav;
		return () => void delete self.__nav;
	}, []);
	return (
		<LocalizationProvider locale="en">
			<>
				<Toaster />
				<Outlet />
			</>
		</LocalizationProvider>
	);
};

export default AppLayout;
