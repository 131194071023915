import type {
	Currency,
	Event as RetailOrderEvent,
	RetailOrder,
} from "@launerlondon/shared";
import { convertAmount, getOrderTotal } from "@launerlondon/shared";
import type { StripeCardElement } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Vue from "vue";
import countries from "../../assets/countries.json";
import states from "../../assets/usStates.json";
import { firestore, functions } from "../lib/firebase";

interface Component extends Vue {
	card: StripeCardElement;
	paymentMethodId: string;
	order: RetailOrder;
	adjust: { currency: Currency; price: number };
	wireTransfer: boolean;
	transfer: { ref: string; date: Date };
	countries: typeof countries;
	states: typeof states;
}

const STRIPE_PK = import.meta.env.VITE_STRIPE_PK;

async function placeOrder(this: Component, event: Event): Promise<void> {
	const form = event.target as HTMLFormElement;
	const button = form.querySelector("button") as HTMLButtonElement;
	const orderId = this.$route.params.orderId;

	button.disabled = true;
	button.classList.add("app-button_loading");

	try {
		if (this.wireTransfer) {
			/**
			 * Since dates are being sent thru httpsCallable, those aren't
			 * automatically converted into Timestamp, so let's send as millis
			 * instead and parse it back on the server
			 */
			const eventDate = this.transfer.date.valueOf() as unknown as Date;
			const event: RetailOrderEvent = {
				type: "charged",
				date: eventDate,
				message: "Wire transfer received",
				extra: { transactionId: this.transfer.ref },
			};

			// first, convert amount so any notification is fired with correct pricing
			// TODO perhaps move this into an endpoint
			const ref = firestore().collection("retail-orders").doc(orderId);
			const prevGross = getOrderTotal(
				this.order,
				this.order._index.roundFactor,
			).gross;
			const nextGross = convertAmount(
				prevGross,
				this.adjust.price,
				this.adjust.currency,
			);
			await ref.update({ "total.gross": nextGross });
			// push new event using enpdpoint
			await functions()
				.httpsCallable("onRetailOrderPushEventCall")({ orderId, event })
				.then(() => this.$emit("close"));
		} else {
			await functions()
				.httpsCallable("sendOrderCallable")({
					orderId,
					paymentMethodId: this.paymentMethodId,
					adjust: this.adjust,
				})
				.then(() => this.$emit("close"));
		}
	} catch (error: any) {
		console.error(error);
		alert(error.message);
	}

	button.classList.remove("app-button_loading");
	button.disabled = false;
}

async function changePm(
	this: Component,
	method: "wireTransfer" | "card",
): Promise<void> {
	this.wireTransfer = method === "wireTransfer";
	if (method === "card") {
		setTimeout(() => this.card.mount("#card-element"), 200);
	}
}

export default Vue.component("retail-order-transaction", {
	data() {
		return {
			card: undefined,
			paymentMethodId: undefined,
			adjust: {},
			wireTransfer: false,
			transfer: {},
			countries,
			states,
		};
	},
	created(this: Component): void {
		if (typeof STRIPE_PK !== "string") {
			throw new Error("missing STRIPE_PK");
		}
		loadStripe(STRIPE_PK, { apiVersion: "2023-10-16" }).then((stripe) => {
			if (!stripe) return;
			const elements = stripe.elements();
			this.card = elements.create("card");
			this.card.mount("#card-element");
			this.card.on("change", async (event) => {
				if (event.complete) {
					const pm = await stripe.createPaymentMethod({
						type: "card",
						card: this.card,
					});
					if (pm.error) {
						alert(pm.error.message);
					} else {
						this.paymentMethodId = pm.paymentMethod.id;
					}
				}
			});
		});
		this.adjust.currency = this.order.payment.currency;
		this.adjust.price =
			getOrderTotal(this.order, this.order._index.roundFactor).gross[
				this.adjust.currency
			] || 0;
	},
	methods: { placeOrder, changePm },
	props: ["order"],
	template: `
	<form class=retail-order-transaction @submit.prevent=placeOrder>
		<h2 class=app-modal__title>Transaction for order {{order.id}}</h2>
		<div class='retail-order-transaction__fieldset retail-order-transaction__fieldset_amount'>
			<label class=app-field>
				<span class=app-field__label>Amount</span>
				<input class=app-field__input v-model.number=adjust.price required>
			</label>
			<label class=app-field>
				<span class=app-field__label>Currency</span>
				<select class=app-field__input v-model=adjust.currency required>
					<option value=gbp>GBP</option>
					<option value=eur>EUR</option>
					<option value=usd>USD</option>
				</select>
			</label>
		</div>
		<div class='retail-order-transaction__fieldset retail-order-transaction__fieldset_card-info'>
			<template v-if=wireTransfer>
				<h3 class='retail-order-transaction__fieldset__title'>
					<span>Wire transfer information</span>
					<small class='retail-order-transaction__fieldset__link' @click="changePm('card')">(Process credit card payment?)</small>
				</h3>
				<label class=app-field>
					<span class=app-field__label>Transfer reference</span>
					<input class=app-field__input v-model=transfer.ref placeholder='REFERENCE' required>
				</label>
				<label class=app-field>
					<span class=app-field__label>Received at</span>
					<input class=app-field__input type=date @input="transfer.date = $event.target.valueAsDate" required>
				</label>
			</template>
			<template v-else>
				<h3 class='retail-order-transaction__fieldset__title'>
					<span>Card information</span>
					<small class='retail-order-transaction__fieldset__link' @click="changePm('wireTransfer')">(Wire transfer payment received?)</small>
				</h3>
				<label style="display:block;width:100%">
					<div id=card-element></div>
				</label>
			</template>
		</div>
		<button class=app-button type=submit :disabled='!(wireTransfer || paymentMethodId)'>Process Payment</button>
	</form>
	`,
});
