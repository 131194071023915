import Vue from "vue";
import { auth } from "../lib/firebase";
import { log } from "../lib/utils";

import type firebase from "firebase";

type User = firebase.User;

interface AuthState {
	authorized: boolean;
	user: User;
	userType: "admin" | "member";
}

interface Component extends Vue {
	authState: AuthState;
	email: string;
	linkSent: boolean;
}

function signin(this: Component, e: Event): void {
	e.preventDefault();
	const button = this.$refs.submitButton as HTMLButtonElement | undefined;

	if (button !== undefined) {
		button.disabled = true;
		button.textContent = "Logging you in...";
	}

	const opts = { handleCodeInApp: true, url: location.href };
	auth()
		.sendSignInLinkToEmail(this.email, opts)
		.then(() => {
			localStorage.setItem("userEmail", this.email);
			this.linkSent = true;
		})
		.catch((error: Error) => {
			if (button !== undefined) {
				button.disabled = false;
				button.textContent = "Sign in";
			}
			log.error(error);
		});
}

export default Vue.component("app-signin", {
	data() {
		return {
			email: undefined,
			linkSent: false,
		};
	},
	methods: { signin },
	props: ["authState"],
	template: `
	<div class=app-signin>
		<form @submit=signin>
			<div class=container>
				<template v-if='linkSent'>
					<div class=link-sent>
						<h2>Please check your inbox</h2>
						<p>We have sent you an email containing your access link.</p>
					</div>
				</template>
				<template v-else>
					<div class=logo></div>
					<input v-model=email
						type=email
						placeholder='email address'
						autocorrect=false
						required>
					<button ref=submitButton>Login</button>
				</template>
				<div id='recaptcha-container'></div>
			</div>
		</form>
	</div>
	`,
});
