import { Order } from "@launerlondon/shared";
import Vue from "vue";
import { firestore } from "../lib/firebase";
import { log } from "../lib/utils";

// @types
import { Account } from "../lib/wholesaleAccount";

interface Component extends Vue {
	orders: Order[];
	accounts: Account[];
	userType: string;
	getCurrentOrdersTotalForAccount: typeof getCurrentOrdersTotalForAccount;
}

const db = firestore();

async function deleteAccount(account: Account): Promise<void> {
	log.info(account);
	if (confirm("Are you sure you want to remove this account?")) {
		log.info("DELETE NOW");
		await db
			.collection("accounts")
			.doc(account.id)
			.update({ disabled: true });
	}
}

function getCurrentOrdersTotalForAccount(
	this: Component,
	account: Account,
): number {
	if (account._counter !== undefined) {
		let { open, completed } = account._counter.orders;
		if (open === undefined) {
			open = 0;
		}
		if (completed === undefined) {
			completed = 0;
		}
		const active = open - completed;

		return active;
	}

	return 0;
}

function filterAccounts(this: Component) {
	const accounts = this.accounts.reduce<Record<"active" | "idle", Account[]>>(
		(p, c) => {
			const isActive = this.getCurrentOrdersTotalForAccount(c) > 0;
			isActive ? p.active.push(c) : p.idle.push(c);
			return p;
		},
		{ active: [], idle: [] },
	);
	accounts.idle.sort((a, b) => a.name.localeCompare(b.name));
	return accounts;
}

export default Vue.component("account-list", {
	methods: {
		deleteAccount,
		getCurrentOrdersTotalForAccount,
		filterAccounts,
	},
	async created(this: Component) {
		const r = await db
			.collection("wholesale-orders")
			.where("completionDate", "!=", false)
			.orderBy("completionDate", "desc")
			.limit(50)
			.get();
		const docs = r.docs
			.map((d) => ({ ...d.data(), id: d.id }) as Order)
			.sort((a, b) => (a.date > b.date ? -1 : 1));
		this.$set(this.$data, "orders", docs);
	},
	props: ["accounts", "userType"],
	data() {
		return { orders: [] };
	},
	template: `
	<section class="flex flex-col gap-4">
		<router-link class='absolute top-7 right-7 z-40 card_add'
			to=/accounts/new
			v-if='userType==="admin"'>
			Add account
		</router-link>
		<details open>
			<summary class=app-section-header>Current orders</summary>
			<div class="my-4 grid grid-cols-3 gap-4 _account-list__card-container">
				<div class=account-list__card
					v-for='acc in filterAccounts().active'
					:key=acc.id>
					<app-popup-menu class=account-list__card__popup-menu v-if='userType==="admin"'>
						<router-link :to="'/accounts/' + acc.id">View orders</router-link>
						<router-link :to="'/accounts/' + acc.id + '/edit'">Edit account details</router-link>
						<router-link :to="'/accounts/' + acc.id + '/orders/new'">Create order</router-link>
						<a class=app-popup-menu__links__link_danger href=# @click=deleteAccount(acc)>Delete account</a>
					</app-popup-menu>
					<router-link class=account-list__card__img
						:style="'background-image:url(' + acc.logo + ')'"
						:to="'/accounts/' + acc.id"></router-link>
					<div class=account-list__card__order-stats>
						Current orders: {{getCurrentOrdersTotalForAccount(acc)}}
					</div>
				</div>
			</div>
		</details>
		<details open>
			<summary class=app-section-header>Other accounts</summary>
			<div>
				<div v-for='acc in filterAccounts().idle' :key=acc.id class='relative flex justify-between border-b'>
					<router-link :to="'/accounts/' + acc.id" class='flex-1 p-2'>{{acc.name}}</router-link>
					<app-popup-menu class="p-2 account-list__card__popup-menu" v-if='userType==="admin"'>
						<router-link :to="'/accounts/' + acc.id">View orders</router-link>
						<router-link :to="'/accounts/' + acc.id + '/edit'">Edit account details</router-link>
						<router-link :to="'/accounts/' + acc.id + '/orders/new'">Create order</router-link>
						<a class=app-popup-menu__links__link_danger href=# @click=deleteAccount(acc)>Delete account</a>
					</app-popup-menu>
				</div>
			</div>
		</details>
		<details open>
			<summary class=app-section-header>Completed orders</summary>
			<order-table :orders=orders hideStatus={true} showAccount={true}></order-table>
		</details>
	</section>
	`,
});
