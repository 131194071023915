import firebase from "firebase";

export async function getUserType() {
	const claims = (await firebase.auth().currentUser?.getIdTokenResult())
		?.claims;
	const userType = claims?.admin
		? "admin"
		: claims?.factory
		? "factory"
		: undefined;
	return userType;
}
