import Vue from "vue";
import { fmtCurrency } from "../lib/utils";

// @types
import { Order, orderTotal } from "../lib/wholesaleOrder";

function goToOrder(this: Vue, order: Order): void {
	this.$router.push({
		name: "orderView",
		params: {
			accountId: order.accountId,
			orderId: order.id,
		},
	});
}

export default Vue.component("order-table", {
	methods: {
		fmtCurrency,
		goToOrder,
		orderTotal,
	},
	props: ["orders", "hideStatus", "showAccount"],
	template: `
	<table class=order-table>
		<tr class=order-table__header>
			<th class=order-table__header__cell>Total items</th>
			<th v-if='showAccount' class=order-table__header__cell>Account</th>
			<th class=order-table__header__cell>P.O. Number</th>
			<th class=order-table__header__cell>Order Date</th>
			<th class=order-table__header__cell>Completion Date</th>
			<th v-if='!hideStatus' class=order-table__header__cell>Fulfilment Status</th>
			<th class='order-table__header__cell order-table__header__cell_price'>Order Value</th>
		</tr>
		<tr v-for='order in orders' :key=order.id class=order-table__row @click=goToOrder(order)>
			<td class=order-table__row__cell>{{order.items.length}}</td>
			<td v-if='showAccount' class=order-table__row__cell>{{order.accountId.replace(/-/g, ' ')}}</td>
			<td class=order-table__row__cell>{{order.reference}}</td>
			<td class=order-table__row__cell>{{order.date.toDate().toLocaleDateString()}}</td>
			<td class=order-table__row__cell>{{order.dueDate?.toDate().toLocaleDateString()}}</td>
			<td v-if='!hideStatus' class='order-table__row__cell order-table__row__cell_status'>{{order.shippingDate ? 'Shipped' : 'Completed'}}</td>
			<td class='order-table__row__cell order-table__row__cell_price'>{{fmtCurrency(orderTotal(order))}}</td>
		</tr>
		<tr v-if='orders.length===0' class=order-table__row>
			<td class='order-table__row__cell order-table__row__cell_empty' colspan=6>
				No completed orders so far
			</td>
		</tr>
	</table>
	`,
});
