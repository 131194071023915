import { AppRouter } from "@launerlondon/orders-router";
import { createRoot, Root } from "react-dom/client";
import Vue from "vue";

let root: Root;

export default Vue.component("react-wrapper", {
	props: ["roles"],
	template: "<div id=app></div>",
	beforeCreate() {
		if (
			/* prettier-ignore */
			(/^\/(cms)/.test(this.$route.path) && !this.$props.roles.includes('cms')) ||
			(/^\/(vouchers)/.test(this.$route.path) && !this.$props.roles.includes('vouchers')) ||
			(/^\/(stats)/.test(this.$route.path) && !this.$props.roles.includes('stats'))
		) {
			this.$router.replace("/");
		} else {
		}
	},
	mounted() {
		root = createRoot(document.getElementById("app")!);
		root.render(<AppRouter />);
	},
	destroyed() {
		root.unmount();
	},
});
