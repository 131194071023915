import Vue from "vue";
import { firestore } from "../lib/firebase";
import { fmtCurrency, log } from "../lib/utils";

import "./wholesaleOrderImport";
import "./wholesaleOrderItemList";
import "./wholesaleOrderItemNew";

import {
	isCompleteOrder,
	getOrderLastCompletedItemDate,
} from "../lib/wholesaleOrder";
import { fmtOrderItemOptions } from "./wholesaleOrderItemNew";

import FileSaver from "file-saver";
import { convertOrderToCSV, orderTotal } from "../lib/wholesaleOrder";

import type { Account } from "../lib/wholesaleAccount";
import type { AuthState } from "./appAuth";
import type { Order, OrderItem } from "../lib/wholesaleOrder";

interface Component extends Vue {
	userType: AuthState["userType"];
	account: Account;
	order: Order;
}

const db = firestore();

function reset(this: Component): void {
	const initialData = this.$options.data as () => Record<string, unknown>;
	log.info(initialData());
	Object.assign(this.$data, initialData());
}

function exportCSV(this: Component): void {
	const content = convertOrderToCSV(this.order);
	const blob = new Blob([content], { type: "text/csv;charset=utf-8" });
	FileSaver.saveAs(blob, `launer-order-${this.order.id}.csv`);
}

async function deleteOrder(this: Component): Promise<void> {
	if (confirm("Are you are you want to delete this order?")) {
		await db.collection("wholesale-orders").doc(this.order.id).delete();
		this.$router.push({
			name: "accountView",
			params: { accountId: this.account.id },
		});
		reset.call(this);
	}
}

async function updateOrder(this: Component): Promise<void> {
	this.order.accountId = this.account.id;
	/* Remove undefined or empty option values before saving
	 * since Firestore doesnt accept undefined a value */
	this.order.items.forEach((item: OrderItem): void => {
		fmtOrderItemOptions(item.options);
	});

	this.order._meta.orderTotal = orderTotal(this.order);
	this.order._meta.lastUpdated = new Date();

	if (
		this.order.completionDate === undefined &&
		isCompleteOrder(this.order)
	) {
		this.order.completionDate = getOrderLastCompletedItemDate(this.order);
	}

	if (this.order._meta.createdAt === undefined) {
		this.order._meta.createdAt = this.order.date;
	}

	const col = db.collection("wholesale-orders");
	const doc = "id" in this.order ? col.doc(this.order.id) : col.doc();
	await doc.set(this.order);

	const accountId = this.order.accountId;
	this.$router.push({ name: "accountView", params: { accountId } });
	reset.call(this);
}

async function markOrderAsShipped(this: Component): Promise<void> {
	const message = "Are you sure you want to mark this order as shipped?";
	if (isCompleteOrder(this.order) && confirm(message)) {
		this.order.shippingDate = new Date();
		await updateOrder.call(this);
	}
}

const computed = {
	orderTotal(this: Component): string {
		const total = this.order.items.reduce(
			(p: number, c: OrderItem): number => {
				const sum = p + c.price;

				return sum;
			},
			0,
		);

		return fmtCurrency(total);
	},
	canBeShipped(this: Component): boolean {
		return (
			this.order.shippingDate === undefined &&
			this.order.completionDate !== undefined &&
			this.userType === "factory" &&
			isCompleteOrder(this.order)
		);
	},
};

function fmtOrder(id: string, order: Order): Order {
	order.id = id;
	if (order.date instanceof firestore.Timestamp) {
		order.date = order.date.toDate();
	}
	if (order.dueDate instanceof firestore.Timestamp) {
		order.dueDate = order.dueDate.toDate();
	}
	if (order.shippingDate instanceof firestore.Timestamp) {
		order.shippingDate = order.shippingDate.toDate();
	}
	order.items.forEach((item: OrderItem): void => {
		if (
			item.status.prepare !== undefined &&
			item.status.prepare instanceof firestore.Timestamp
		) {
			item.status.prepare = item.status.prepare.toDate();
		}
		if (
			item.status.cut !== undefined &&
			item.status.cut instanceof firestore.Timestamp
		) {
			item.status.cut = item.status.cut.toDate();
		}
		if (
			item.status.complete !== undefined &&
			item.status.complete instanceof firestore.Timestamp
		) {
			item.status.complete = item.status.complete.toDate();
		}
	});

	return order;
}

async function mounted(this: Component): Promise<void> {
	const orderId = this.$route.params.orderId;
	if (orderId !== "new") {
		const doc = await db.collection("wholesale-orders").doc(orderId).get();
		if (doc.exists) {
			this.order = fmtOrder(doc.id, doc.data() as Order);
			this.$set(this.$data, "orderId", doc.id);
		}
	}
}

export default Vue.component("order-edit", {
	computed,
	data() {
		return {
			orderId: undefined,
			order: {
				_meta: {},
				date: new Date(),
				items: [],
			},
		};
	},
	methods: {
		deleteOrder,
		exportCSV,
		markOrderAsShipped,
		updateOrder,
	},
	mounted,
	props: ["account", "userType"],
	template: `
	<form class=order-edit @submit.prevent=updateOrder>
		<app-popup-menu v-if="order.id && userType === 'admin'">
			<a href=# @click=exportCSV>Export as CSV</a>
			<a href=# class=app-popup-menu__links__link_danger @click=deleteOrder>Delete Order</a>
		</app-popup-menu>
		<div class=order-edit__fieldset>
			<label class=app-field>
				<span class=app-field__label>Purchase order number</span>
				<input class=app-field__input
					required
					placeholder=1075-617440
					v-model=order.reference
					:disabled='userType === "factory"'>
			</label>
			<label class=app-field>
				<span class=app-field__label>Order date</span>
				<input class=app-field__input type=date
					:value="order.date && order.date.toISOString().split('T')[0]"
					@input="order.date = $event.target.valueAsDate" required :disabled='userType==="factory"'>
			</label>
			<label class=app-field>
				<span class=app-field__label>Required date</span>
				<input class=app-field__input type=date
					:value="order.dueDate && order.dueDate.toISOString().split('T')[0]"
					@input="order.dueDate = $event.target.valueAsDate" required :disabled='userType==="factory"'>
			</label>
			<label class='app-field order-edit__field_price' title='Calculated by the sum of order item prices'>
				<span class=app-field__label>Order value</span>
				<input class=app-field__input v-model=orderTotal disabled>
			</label>
			<label class='app-field app-field_address'>
				<span class=app-field__label>Delivery address</span>
				<select v-model=order.address
						v-if='account.addresses && account.addresses.length > 0'
						class='app-field__input app-field__input_select'
						:disabled='userType==="factory"'
						required>
					<option disabled :value=undefined>Please select</option>
					<option v-for='addr in account.addresses' :key=addr.postcode :value=addr>
						{{addr.line1}}, {{addr.city}}, {{addr.postcode}}
					</option>
				</select>
				<router-link class='app-field__input app-field__input_link'
					v-else :to="'/accounts/' + account.id + '/edit'">
					No address for this account. Click to add one.
				</router-link>
			</label>
			<label class=app-field v-if=order.shippingDate>
				<span class=app-field__label>Shipping date</span>
				<input class=app-field__input type=date required disabled
					:value="order.shippingDate && order.shippingDate.toISOString().split('T')[0]">
			</label>
		</div>

		<order-item-list :items=order.items :user-type=userType></order-item-list>

		<template v-if='userType==="admin"'>
			<header class=app-section-header>Add products to this order</header>
			<order-item-new v-bind:items=order.items></order-item-new>

			<h2 class=app-section-header>Import from file</h2>
			<order-import :order=order></order-import>
		</template>

		<label class=order-edit__note>
			<span class=app-section-header>Internal messages</span>
			<react-wrapper v-if='orderId' />
		</label>

		<button v-if=canBeShipped
			@click=markOrderAsShipped
			type=button
			class='app-button app-button_center'>
			Mark order as shipped
		</button>
		<button v-else
			type=submit
			class='app-button app-button_center'>
			Update this order
		</button>
	</form>
	`,
});
