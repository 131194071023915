import { Dialog } from "@headlessui/react";
import {
	BoltIcon,
	BoltSlashIcon,
	ClockIcon,
	GiftIcon,
} from "@heroicons/react/24/outline";
import {
	fmtOrderRef,
	getVoucherExpiryDate,
	getVoucherStatus,
	tsToDate,
	VoucherEvent,
	VoucherRaw,
} from "@launerlondon/shared";
import { Suspense } from "react";
import {
	Await,
	Link,
	useAsyncValue,
	useLoaderData,
	useNavigate,
	useSubmit,
} from "react-router-dom";

const Event: React.FC<{ event: VoucherEvent }> = ({ event }) => {
	const date = tsToDate(event.date);
	return (
		<tr key={event.type}>
			<td title={date.toLocaleString()}>{date.toLocaleDateString()}</td>
			<td className="">
				<div>
					<div className="w-max px-1 text-center">{event.type}</div>
				</div>
			</td>
			<td>
				{event.order ? (
					<Link
						reloadDocument
						to={`/retail/orders/${event.order.id}/${event.order.ref}`}
						className="text-blue-500 underline"
					>
						#{fmtOrderRef(event.order?.ref)}
					</Link>
				) : (
					"-"
				)}
			</td>
			<td className="text-right">
				{["create", "refund"].includes(event.type) && "+"}
				{event.type === "redeem" && "-"}
				{event.amount || event.message || "-"}
			</td>
		</tr>
	);
};

const Item: React.FC = () => {
	const voucher = useAsyncValue() as VoucherRaw;
	const submit = useSubmit();
	const order = voucher.events[0].order;
	const percentage = (voucher.amount / voucher.initialAmount) * 100;
	const status = getVoucherStatus(voucher);
	return (
		<div className="space-y-4">
			<div className="flex justify-between gap-10 font-sans">
				<div className="space-y-3">
					<h3 className="">
						<span className="text-4xl">
							{voucher.amount}/{voucher.initialAmount}
						</span>
						<span>{voucher.currency}</span>
					</h3>
					<div className="relative h-2 w-full overflow-hidden rounded-lg bg-gray-300">
						<div
							className="absolute left-0 z-30 h-2 rounded-r-lg bg-purple-400"
							style={{ width: `${percentage}%` }}
						/>
					</div>
				</div>
				<div>
					<h4 className="font-mono text-lg">{voucher.code}</h4>
					<div className="my-2 space-y-1 text-xs capitalize">
						{order && (
							<div className="flex gap-1">
								<GiftIcon className="h-4 w-4" />
								<h4>Parent Order</h4>
								<Link
									reloadDocument //retail not linked to react-router
									to={`/retail/orders/${order.id}/${order.ref}`}
									className="text-blue-500 underline"
								>
									#{fmtOrderRef(order.ref)}
								</Link>
							</div>
						)}

						<button
							className="flex items-center gap-1"
							title={`Click to ${
								status == "active" ? "disable" : "enable"
							}`}
							onClick={() =>
								submit(
									{ disabled: !voucher.disabled },
									{ method: "PATCH" },
								)
							}
						>
							{status === "active" ? (
								<BoltIcon className="h-4 w-4" />
							) : (
								<BoltSlashIcon className="h-4 w-4" />
							)}
							{status}
						</button>
						<div className="flex items-center gap-1">
							<ClockIcon className="h-4 w-4" />
							expires{" "}
							{getVoucherExpiryDate(voucher).toLocaleDateString()}
						</div>
					</div>
				</div>
			</div>
			<div>
				<h3 className="mb-2 font-bold">Events</h3>
				<table className="w-full text-left text-sm" cellPadding={4}>
					<thead>
						<tr className="border-b">
							<th>Timestamp</th>
							<th className="w-full">Type</th>
							<th>Order</th>
							<th className="text-right">Detail</th>
						</tr>
					</thead>
					<tbody className="divide-y">
						{voucher.events.map((e, i) => (
							<Event key={i} event={e} />
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

const VoucherView: React.FC = () => {
	const data = useLoaderData() as { item: Promise<VoucherRaw> };
	const navigate = useNavigate();
	return (
		<Dialog
			open
			onClose={() => navigate("/vouchers")}
			className="fixed inset-0 flex items-center justify-center"
		>
			<div className="absolute inset-0 bg-black/25" />
			<Dialog.Panel className="relative max-h-[90vh] w-full max-w-md overflow-auto rounded bg-white p-4 shadow-lg">
				<Suspense fallback="loading…">
					<Await resolve={data.item}>
						<Item />
					</Await>
				</Suspense>
			</Dialog.Panel>
		</Dialog>
	);
};

export default VoucherView;
