import Vue from "vue";
import { auth } from "../lib/firebase";
import { log } from "../lib/utils";

async function logout(this: Vue, event: Event): Promise<void> {
	event.preventDefault();

	localStorage.removeItem("userEmail");
	this.$router.push("/");
	auth().signOut().catch(log.error);
}

export default Vue.component("app-header", {
	methods: { logout },
	props: ["roles"],
	data() {
		return { isDev: import.meta.env.DEV };
	},
	template: `
	<header class=app-header>
		<router-link to=/ class=app-header__logo>
			<h1 class=logo>Launer London - Ordering Platform</h1>
		</router-link>
		<router-link v-if="roles.includes('retail')" :to='{name:"retailOrderList"}' class='app-header__link app-header__link_retail'>Online orders</router-link>
		<router-link v-if="roles.includes('vouchers')" to='/vouchers' class='app-header__link app-header__link_vouchers'>Vouchers</router-link>
		<router-link v-if="roles.includes('wholesale')" to=/accounts class='app-header__link app-header__link_wholesale'>Wholesale orders</router-link>
		<router-link v-if="roles.includes('stats')" to=/stats class='app-header__link app-header__link_stats'>Stats</router-link>
		<router-link v-if="roles.includes('cms')" to=/cms class='app-header__link app-header__link_cms'>CMS</router-link>
		<a href=# class='app-header__link app-header__link_logout' @click=logout>Log-out</a>
	</header>
	`,
});
