import Vue from "vue";

// @types
import { OrderItemStatus } from "../lib/wholesaleOrder";

interface Component extends Vue {
	userType: string;
	label: Date | undefined;
	status: OrderItemStatus;
	step: "cut" | "prepare" | "complete";
}

function updateStatus(this: Component): void {
	const step = this.status[this.step];
	if (step === undefined) {
		this.$set(this.status, this.step, new Date());
	} else {
		this.$delete(this.status, this.step);
	}
	this.label = this.status[this.step];
}

Vue.component("order-item-list-status", {
	computed: {
		stepStatus(this: Component): string {
			if (this.label !== undefined) {
				return this.label.toLocaleDateString();
			}

			return "";
		},
	},
	data(this: Component): object {
		return {
			label: this.status[this.step],
		};
	},
	methods: { updateStatus },
	props: ["status", "step", "userType"],
	template: `
		<div :class="'order-item-list-status' +  (' order-item-list-status_' + step) +  (label ? ' order-item-list-status__done' : '')">
			<button type=button
				class=order-item-list-status__button
				:disabled='userType==="admin"'
				@click=updateStatus>
			</button>
			<span class=order-item-list-status__timestamp>{{stepStatus}}</span>
		</div>
	`,
});
