import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
	FocusEventHandler,
	Suspense,
	useCallback,
	useEffect,
	useRef,
} from "react";
import {
	Await,
	Form,
	useAsyncValue,
	useLoaderData,
	useNavigation,
	useSubmit,
} from "react-router-dom";

type StockItem = { id: string; total: number; counter: number };

const Item: React.FC<{ item: StockItem }> = ({ item }) => {
	const submit = useSubmit();
	const onDelete = useCallback(() => {
		if (!confirm(`Confirm deletion of stock ${item.id}?`)) return;
		submit({ sku: item.id }, { method: "delete" });
	}, []);
	const onUpdate: FocusEventHandler<HTMLInputElement> = useCallback((e) => {
		const total = e.currentTarget.value;
		if (!total || Number.isNaN(Number(total))) {
			e.currentTarget.value = item.total.toString();
			return;
		}
		submit({ sku: item.id, total }, { method: "put" });
	}, []);
	return (
		<tr>
			<td>{item.id}</td>
			<td>
				<input
					placeholder="0"
					onBlur={onUpdate}
					defaultValue={item.total}
				/>
			</td>
			<td>{item.counter}</td>
			<td>
				<button onClick={onDelete}>
					<TrashIcon className="h-4 w-4" />
				</button>
			</td>
		</tr>
	);
};

const Items: React.FC = () => {
	const items = useAsyncValue() as StockItem[];
	const formRef = useRef<HTMLFormElement>(null);
	const { state } = useNavigation();
	useEffect(() => {
		if (state === "idle") {
			formRef.current?.reset();
		}
	}, [state]);
	return (
		<table className="my-4 w-full text-left">
			<thead>
				<tr>
					<th>SKU</th>
					<th>Total</th>
					<th>Orders</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				{items.map((i) => (
					<Item key={i.id} item={i} />
				))}
				<tr>
					<td>
						<Form ref={formRef} id="create" method="post">
							<input name="sku" placeholder="1234-12" required />
						</Form>
					</td>
					<td>
						<input
							form="create"
							name="total"
							type="number"
							placeholder="10"
							required
						/>
					</td>
					<td>
						<input disabled placeholder="0" required />
					</td>
					<td>
						<button form="create" disabled={state === "loading"}>
							<PlusCircleIcon className="h-4 w-4" />
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

const AdminStock: React.FC = () => {
	const data = useLoaderData() as {
		items: Promise<StockItem>;
	};
	return (
		<div>
			<Suspense>
				<Await resolve={data.items} children={<Items />} />
			</Suspense>
		</div>
	);
};

export default AdminStock;
