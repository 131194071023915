import Vue from "vue";
import { fmtCurrency, log } from "../lib/utils";
import { watch as watchDb } from "../lib/watchDb";

// tslint:disable no-import-side-effect
import "./wholesaleOrderCard";
import "./wholesaleOrderTable";

// @types
import { DocChangeCallback } from "../lib/watchDb";
import { Account } from "../lib/wholesaleAccount";
import { isCompleteOrder, Order, ordersTotal } from "../lib/wholesaleOrder";

interface Data {
	currentOrders: Order[];
	completeOrders: Order[];
}
interface Component extends Data, Vue {
	account: Account;
}

function onOrderUpdate(this: Component, changeType: string, doc: object): void {
	const order = doc as Order;
	if (changeType === "added") {
		if (isCompleteOrder(order)) {
			this.completeOrders.push(order);
		} else {
			this.currentOrders.push(order);
		}
	}
	if (changeType === "removed") {
		log.info("REMOVED");
	}
}

function mounted(this: Component): void {
	const accountId = this.$route.params.accountId || "0000";
	watchDb({
		collection: "wholesale-orders",
		where: [["accountId", "==", accountId]],
		orderBy: ["date", "desc"],
		onDoc: onOrderUpdate.bind(this) as DocChangeCallback,
	});
}

export default Vue.component("account-view", {
	data(): Data {
		return {
			currentOrders: [] as Order[],
			completeOrders: [] as Order[],
		};
	},
	methods: {
		fmtCurrency,
		ordersTotal,
	},
	mounted,
	props: ["account", "userType"],
	template: `
	<section class=account-view>
		<router-link class='absolute top-7 right-7 card_add'
			:to="'/accounts/' + account.id + '/orders/new'"
			v-if='userType==="admin"'>
			Create order
		</router-link>
		<header class=app-section-header>
			<h2>Current orders</h2>
			<h3>Total: {{fmtCurrency(ordersTotal(currentOrders))}}</h3>
		</header>
		<div class='flex flex-col gap-4 my-4'>
			<order-card v-for='order in currentOrders' :key=order.id :order=order></order-card>
		</div>
		<header class=app-section-header>
			<h2>Completed orders</h2>
			<h3>Total: {{fmtCurrency(ordersTotal(completeOrders))}}</h3>
		</header>
		<order-table :orders=completeOrders hideStatus={true}></order-table>
	</section>
	`,
});
