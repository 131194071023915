import Vue from "vue";
import { firestore, storage } from "../lib/firebase";
import { log } from "../lib/utils";

import type firebase from "firebase";

// tslint:disable no-import-side-effect
import "./wholesaleAddressList";
import "./wholesaleAddressNew";

// @types
type UploadTaskSnapshot = firebase.storage.UploadTaskSnapshot;
import { Account } from "../lib/wholesaleAccount";

interface Component extends Vue {
	account: Account;
}

const db = firestore();

function generateAccountId(name: string, id?: string): string {
	if (id !== undefined) {
		return id;
	}

	return name.toLowerCase().replace(/ /g, "-");
}

async function updateAccount(
	this: Component,
	event: MouseEvent,
): Promise<void> {
	event.preventDefault();
	this.account.createdAt = new Date();
	await db
		.collection("accounts")
		.doc(generateAccountId(this.account.name, this.account.id))
		.set(this.account);
	this.$router.push("/accounts");
}

function uploadLogo(this: Component, event: Event): void {
	const component: Component = this; // eslint-disable-line @typescript-eslint/no-this-alias
	const input = event.target as HTMLInputElement;
	const file = input.files?.[0];
	if (!file) return;
	const ref = `accounts/logos/${file?.name}`;
	const task = storage().ref(ref).put(file);

	task.on(storage.TaskEvent.STATE_CHANGED, {
		next(snapshot: UploadTaskSnapshot): void {
			const progress = snapshot.bytesTransferred / snapshot.totalBytes;
			const percentage = Math.ceil(progress * 100);
			log.info(`upload process ${percentage}`);
		},
		error(error: Error): void {
			log.error(error);
		},
		async complete(): Promise<void> {
			const logoUrl =
				(await task.snapshot.ref.getDownloadURL()) as string;
			log.info(`upload finished ${logoUrl}`);
			component.$set(component.account, "logo", logoUrl);
		},
	});
}

export default Vue.component("account-edit", {
	methods: { updateAccount, uploadLogo },
	props: {
		account: {
			default() {
				return {
					addresses: [],
				};
			},
			type: Object,
		},
	},
	template: `
	<form class=account-edit @submit=updateAccount>
		<div class=account-edit__fieldset>
			<label class=app-field>
				<span class=app-field__label>Company name</span>
				<input class=app-field__input v-model=account.name placeholder='Acme Inc.' required>
			</label>
			<label class=app-field>
				<span class=app-field__label>Contact name</span>
				<input class=app-field__input v-model=account.representative placeholder='John Doe' required>
			</label>
			<label class=app-field>
				<span class=app-field__label>Contact email address</span>
				<input class=app-field__input v-model=account.email type=email placeholder=john.doe@email.com required>
			</label>
			<label class=app-field>
				<span class=app-field__label>Contact phone</span>
				<input class=app-field__input v-model=account.tel type=tel placeholder='07876 766 689' required>
			</label>
			<label class=app-field>
				<span class=app-field__label>Company notes</span>
				<textarea class='app-field__input app-field__input_textarea' v-model=account.notes placeholder='Important details about the company'></textarea>
			</label>
			<label class='app-field account-edit__field_logo'>
				<span class=app-field__label>Company logo</span>
				<span class=account-edit__field_logo__preview>
					<span class=account-edit__field_logo__preview__image
						v-if=account.logo
						:style="'background-image:url(' + account.logo + ')'">
					</span>
					<span v-else>Upload file</span>
				</span>
				<input class=app-field__input v-on:change=uploadLogo type=file accept=image/*>
			</label>
		</div>

		<h2 class=app-section-header>Delivery Address</h2>
		<account-address-list :addresses=account.addresses></account-address-list>
		<account-address-new :account=account></account-address-new>

		<button class='app-button app-button_center' type=submit>
			Save account
		</button>
	</form>
	`,
});
