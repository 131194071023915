import Vue from "vue";
import { productInfo } from "../lib/productData";
import cloneDeep from "lodash.clonedeep";

// @types
import { ProductInfo, ProductOption } from "../lib/productData";
import { OrderItem, OrderItemOption } from "../lib/wholesaleOrder";

interface Component extends Vue {
	feed: OrderItem;
	item: OrderItem;
	items?: OrderItem[];
	options: ProductOption[];
	quantity: number;
}

export function fmtOrderItemOptions(
	orderItemOptions: OrderItemOption[] | undefined,
): void {
	/* delete empty string or undefined values */
	if (orderItemOptions !== undefined) {
		orderItemOptions.forEach((o: OrderItemOption): void => {
			if (o.value === undefined || o.value.trim() === "") {
				delete o.value;
			}
		});
	}
}

function addItemToOrder(this: Component, event: MouseEvent): void {
	event.preventDefault();
	const orderItem = this.item;
	const orderItems = this.items;

	if (orderItems === undefined) {
		Object.assign(this.feed, orderItem);
		this.$emit("update", orderItem);
	} else {
		const items = Array(this.quantity).fill(orderItem);
		items.forEach((item: OrderItem): void => {
			orderItems.push(cloneDeep(item) as OrderItem);
		});
	}
	reset.call(this);
}

function reset(this: Component): void {
	const initialData = this.$options.data as () => object;
	Object.assign(this.$data, initialData());
}

function getListName(optionName: string): string {
	return `list-${optionName.toLowerCase().replace(/ /g, "-")}`;
}

function productOptionsToOrderItemOptions(
	productOptions: ProductOption[],
): OrderItemOption[] {
	return productOptions.map((option: ProductOption): OrderItemOption => {
		const itemOption = {
			name: option.name,
			value: option.value as string,
		};

		return itemOption;
	});
}

function addValuesToOptions(
	productOptions: ProductOption[],
	orderOptions: OrderItemOption[] | undefined,
): ProductOption[] {
	if (orderOptions === undefined) {
		return productOptions;
	}

	return productOptions.map((productOption: ProductOption) => {
		const orderOption = orderOptions.find((oOption: OrderItemOption) => {
			const label = oOption.name === productOption.name;

			return label;
		});
		const updatedProductOption = { ...productOption };
		if (orderOption !== undefined) {
			updatedProductOption.value = orderOption.value;
		}

		return updatedProductOption;
	});
}

function updateOptions(this: Component, key: "name" | "sku"): void {
	const product = productInfo.find(
		(p: ProductInfo): boolean => p[key] === this.item[key],
	);
	const orderItem = this.item;
	if (product !== undefined) {
		orderItem.name = product.name;
		orderItem.sku = product.sku;
		orderItem.price = product.price;
		const productOptions = addValuesToOptions(
			product.options,
			orderItem.options,
		);
		orderItem.options = productOptionsToOrderItemOptions(productOptions);
	}
}

function getDatalistForOption(
	orderItem: OrderItem,
	optionName: string,
): string[] {
	const product = productInfo.find(
		(p: ProductInfo): boolean => p.name === orderItem.name,
	);
	if (product !== undefined) {
		const productOptions = product.options.find(
			(productOption: ProductOption) => {
				const productOptionName = productOption.name === optionName;

				return productOptionName;
			},
		);

		if (productOptions !== undefined) {
			return productOptions.datalist;
		}

		return [];
	}

	return [];
}

const watch = {
	feed(this: Component, value?: OrderItem): void {
		if (value !== undefined) {
			this.item = cloneDeep(value) as OrderItem;
			/* Do not trigger option update
			 * So original values are not overriden
			 * updateOptions.call(this, 'sku');
			 */
		}
	},
};

export default Vue.component("order-item-new", {
	data(): object {
		return {
			item: { status: {} },
			productInfo,
			quantity: 1,
		};
	},
	methods: {
		addItemToOrder,
		getDatalistForOption,
		getListName,
		updateOptions,
	},
	props: {
		feed: {
			required: false,
			type: Object,
		},
		items: {
			required: false,
			type: Array,
		},
	},
	template: `
		<form class=order-item-new @submit=addItemToOrder>
			<div class=order-item-new__fieldset>
				<label class='app-field order-item-new__field_qty' v-if=items>
					<span class=app-field__label>Quantity</span>
					<input class=app-field__input v-model.number=quantity type=number required>
				</label>
				<label class='app-field order-item-new__field_name'>
					<span class=app-field__label>Product name</span>
					<input class=app-field__input v-model=item.name placeholder=Traviata required list=products @change="updateOptions('name')">
					<datalist id=products>
						<option v-for='product in productInfo' :key=product.sku>{{product.name}}</option>
					</datalist>
				</label>
				<label class='app-field order-item-new__field_sku'>
					<span class=app-field__label>SKU</span>
					<input class=app-field__input v-model=item.sku list=sku placeholder=3050 required @change="updateOptions('sku')">
					<datalist id=sku>
						<option v-for='product in productInfo' :key=product.sku>{{product.sku}}</option>
					</datalist>
				</label>
				<label class='app-field order-item-new__field_price'>
					<span class=app-field__label>Price</span>
					<input class=app-field__input v-model.number=item.price type=text placeholder=250 required>
				</label>
				<label v-for='option in item.options' class='app-field order-item-new__field_option'>
					<span class=app-field__label>{{option.name}}</span>
					<input class=app-field__input
						:list=getListName(option.name)
						v-model=option.value>
					<datalist :id=getListName(option.name)>
						<option v-for='value in getDatalistForOption(item, option.name)'>{{value}}</option>
					</datalist>
				</label>
			</div>
			<button class='app-button app-button_center'>
				{{items ? 'Add' : 'Update'}}
				Product
			</button>
		</form>
	`,
	watch,
});
