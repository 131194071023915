import { swatches } from "@launerlondon/shared";

export interface ProductOption {
	name: string;
	datalist: string[];
	value?: string;
}
export interface ProductInfo {
	name: string;
	sku: string;
	price: number;
	options: ProductOption[];
}

const productColors = swatches
	.filter((group) => group.id !== "S-00")
	.flatMap((g) => g.children)
	.map((s) => `${s.name} (${s.id})`);

const productSuedeColors = swatches
	.filter((group) => group.id === "S-00")
	.flatMap((g) => g.children)
	.map((s) => `${s.name} (${s.id})`);

const options = {
	interiorExterior: [
		{ name: "Exterior", datalist: productColors },
		{ name: "Interior", datalist: productColors },
	],
	purses: [
		{ name: "Exterior", datalist: productColors },
		{ name: "Interior", datalist: productColors },
		{ name: "Emblem", datalist: ["rope", "flat"] },
		{ name: "Faraday shielding", datalist: ["yes", "no"] },
	],
	leatherAndSuede: [
		{ name: "Leather colour", datalist: productColors },
		{ name: "Suede Lining colour", datalist: productSuedeColors },
	],
	pocketWallets: [
		{ name: "Interior", datalist: productColors },
		{ name: "Exterior", datalist: productColors },
		{ name: "Gold corners", datalist: ["no", "1-pair", "2-pairs"] },
		{ name: "Faraday shielding", datalist: ["no", "yes"] },
	],
	accessories: [{ name: "Leather colour", datalist: productColors }],
	handbags: [
		{ name: "Handle", datalist: productColors },
		{ name: "Flap", datalist: productColors },
		{ name: "Body", datalist: productColors },
		{ name: "Gusset", datalist: productColors },
		{ name: "Suede lining colour", datalist: productSuedeColors },
		{ name: "Fittings", datalist: ["Gold plated", "Palladium"] },
	],
};

export const productInfo: ProductInfo[] = [
	{
		sku: "103",
		name: "Snowflake - Large Logo Purse (gusset note pocket)",
		price: 177,
		options: options.purses,
	},
	{
		sku: "205",
		name: "Daisy Trifold Notecase with purse",
		price: 97,
		options: options.purses,
	},
	{
		sku: "228",
		name: "Freesia - Large Flap Over Purse",
		price: 142,
		options: options.purses,
	},
	{
		sku: "232",
		name: "Poppy - Heart-shaped coin purse",
		price: 52,
		options: options.leatherAndSuede,
	},
	{
		sku: "250",
		name: "Violet Medium Logo Japanese Size",
		price: 152,
		options: options.purses,
	},
	{
		sku: "254",
		name: "Jasmine - Zip round ladies purse",
		price: 158,
		options: options.purses,
	},
	{
		sku: "268",
		name: "Lily - Double Zip Round Purse",
		price: 181,
		options: options.purses,
	},
	{
		sku: "685",
		name: "Tulip - Small Logo Purse",
		price: 118,
		options: options.purses,
	},
	{
		sku: "686",
		name: "Azalea - Medium Logo Purse",
		price: 148,
		options: options.purses,
	},
	{
		sku: "738",
		name: "Primrose - Large Logo Purse (gusset note pocket)",
		price: 177,
		options: options.purses,
	},
	{
		sku: "112",
		name: "Coin purse wallet with tab closure",
		price: 108,
		options: options.pocketWallets,
	},
	{
		sku: "201",
		name: "Four Credit Card Wallet with coin purse/Window flap",
		price: 121,
		options: options.pocketWallets,
	},
	{
		sku: "202",
		name: "Eight Credit Card Wallet with coin purse",
		price: 123,
		options: options.pocketWallets,
	},
	{
		sku: "203",
		name: "Sixteen Credit Card Wallet",
		price: 126,
		options: options.pocketWallets,
	},
	{
		sku: "204",
		name: "Twelve Credit Card Wallet",
		price: 121,
		options: options.pocketWallets,
	},
	{
		sku: "486",
		name: "Twelve Credit Card HIP Wallet",
		price: 108,
		options: options.pocketWallets,
	},
	{
		sku: "610",
		name: "Ten Credit Card Wallet",
		price: 97,
		options: options.pocketWallets,
	},
	{
		sku: "717",
		name: "Four Credit Card Wallet with coin purse",
		price: 110,
		options: options.pocketWallets,
	},
	{
		sku: "794",
		name: "Eight Credit Card Wallet with window flap",
		price: 119,
		options: options.pocketWallets,
	},
	{
		sku: "805",
		name: "Eight Credit Card Wallet",
		price: 97,
		options: options.pocketWallets,
	},
	{
		sku: "975",
		name: "Trifold Seven Credit Card Wallet",
		price: 87,
		options: options.pocketWallets,
	},
	{
		sku: "399",
		name: "Breast Pocket Credit card Wallet",
		price: 111,
		options: options.pocketWallets,
	},
	{
		sku: "620",
		name: "Breast Pocket Sixteen Credit Card Wallet",
		price: 132,
		options: options.pocketWallets,
	},
	{
		sku: "659",
		name: "Breast Pocket Card Card and Note Wallet",
		price: 132,
		options: options.pocketWallets,
	},
	{
		sku: "872",
		name: "Japenese Yen Wallet",
		price: 145,
		options: options.pocketWallets,
	},
	{
		sku: "206",
		name: "Four Credit Card Case with Purse",
		price: 79,
		options: options.pocketWallets,
	},
	{
		sku: "239",
		name: "Card case",
		price: 73,
		options: options.pocketWallets,
	},
	{
		sku: "477",
		name: "Six Credit Card Case (sideways pockets)",
		price: 55,
		options: options.pocketWallets,
	},
	{
		sku: "478",
		name: "Business Card Case",
		price: 60,
		options: options.pocketWallets,
	},
	{
		sku: "785",
		name: "Slim Four Credit Card Holder",
		price: 47,
		options: options.pocketWallets,
	},
	{
		sku: "806",
		name: "Six Credit Card Case with Rear Pocket",
		price: 74,
		options: options.pocketWallets,
	},
	{
		sku: "874",
		name: "Slim Six Credit Card Holder",
		price: 48,
		options: options.pocketWallets,
	},
	{
		sku: "882",
		name: "Six Credit Card Case (portrait pockets)",
		price: 68,
		options: options.pocketWallets,
	},
	{
		sku: "925",
		name: "Four Credit Card Case",
		price: 65,
		options: options.pocketWallets,
	},
	{
		sku: "010",
		name: "Handbag Strap",
		price: 37,
		options: [
			{ name: "Leather colour", datalist: productColors },
			{ name: "Fittings", datalist: ["Gold plated", "Palladium"] },
		],
	},
	{
		sku: "011",
		name: "Mirror",
		price: 19,
		options: options.accessories,
	},
	{
		sku: "244",
		name: "I Phone case - all sizes",
		price: 40,
		options: [
			{ name: "Leather colour", datalist: productColors },
			{ name: "Suede lining colour", datalist: productSuedeColors },
			{
				name: "Phone model",
				datalist: [
					"iPhone XS",
					"iPhone XS Max",
					"iPhone XR",
					"iPhone 11",
					"iPhone 11 Pro",
					"iPhone 11 Pro Max",
					"iPhone SE",
					"iPhone 12 mini",
					"iPhone 12",
					"iPhone 12 Pro",
					"iPhone 12 Pro Max",
					"iPhone 13",
					"iPhone 13 Pro",
					"iPhone 13 Pro Max",
					"iPhone 14 Pro",
					"iPhone 14 Pro Max",
					"Samsung Galaxy S20",
					"Samsung Galaxy S20 plus",
					"Samsung Galaxy S21",
					"Samsung Galaxy S21+",
					"Samsung Galaxy S21 Ultra",
					"Samsung Galaxy S22",
					"Samsung Galaxy S22+",
					"Samsung Galaxy S22 Ultra",
					"Google Pixel 5",
					"Google Pixel 6",
					"Google Pixel 6 Pro",
				],
			},
		],
	},
	{
		sku: "245",
		name: "I Pad Case - all Sizes",
		price: 74,
		options: [
			{ name: "Leather colour", datalist: productColors },
			{ name: "Suede lining colour", datalist: productSuedeColors },
			{
				name: "Tablet model",
				datalist: [
					"iPad Mini – 6th Gen",
					"iPad – 9th Gen",
					"iPad Air – 4th/5th Gen",
					"iPad Pro 11-in – 3rd Gen",
					"iPad Pro 12.9-in – 5th Gen",
				],
			},
			{ name: "Faraday Shielding", datalist: ["no", "yes"] },
		],
	},
	{
		sku: "3009",
		name: "Zip Round Document Case",
		price: 323,
		options: options.leatherAndSuede,
	},
	{
		sku: "496",
		name: "Spectacle case - Full",
		price: 42,
		options: options.leatherAndSuede,
	},
	{
		sku: "501",
		name: "Six Hook Key Case",
		price: 52,
		options: options.accessories,
	},
	{
		sku: "517",
		name: "Spectacle case - Half",
		price: 39,
		options: options.leatherAndSuede,
	},
	{
		sku: "670",
		name: "Passport Holder",
		price: 79,
		options: [
			{ name: "Exterior", datalist: productColors },
			{ name: "Interior", datalist: productColors },
			{ name: "Passport foiling", datalist: ["no", "yes"] },
			{ name: "Gold corners", datalist: ["no", "1-pair", "2-pairs"] },
		],
	},
	{
		sku: "726",
		name: "Six Hook Key Case with zip",
		price: 60,
		options: options.interiorExterior,
	},
	{
		sku: "746",
		name: "Key Fob",
		price: 42,
		options: options.accessories,
	},
	{
		sku: "759",
		name: "Travel Wallet",
		price: 169,
		options: options.accessories,
	},
	{
		sku: "920",
		name: "Pen Case",
		price: 52,
		options: options.leatherAndSuede,
	},
	{
		sku: "938",
		name: "Angelo Tray",
		price: 60,
		options: options.leatherAndSuede,
	},
	{
		sku: "2663",
		name: "High Society",
		price: 452,
		options: options.handbags,
	},
	{
		sku: "2663/Z",
		name: "Grace (crytal stud logo)",
		price: 484,
		options: options.handbags,
	},
	{
		sku: "2666",
		name: "Lulu",
		price: 435,
		options: options.handbags,
	},
	{
		sku: "2771",
		name: "Angelika",
		price: 452,
		options: options.handbags,
	},
	{
		sku: "2826",
		name: "Royale",
		price: 903,
		options: options.handbags,
	},
	{
		sku: "2875",
		name: "Elizabeth",
		price: 665,
		options: options.handbags,
	},
	{
		sku: "2900",
		name: "Diva",
		price: 806,
		options: options.handbags,
	},
	{
		sku: "2907",
		name: "Amelia",
		price: 774,
		options: options.handbags,
	},
	{
		sku: "2910",
		name: "Tosca",
		price: 468,
		options: options.handbags,
	},
	{
		sku: "2914",
		name: "Encore",
		price: 774,
		options: options.handbags,
	},
	{
		sku: "2941",
		name: "Maggie",
		price: 1000,
		options: options.handbags,
	},
	{
		sku: "2947",
		name: "Bellini",
		price: 774,
		options: options.handbags,
	},
	{
		sku: "3011",
		name: "Nocturne",
		price: 774,
		options: options.handbags,
	},
	{
		sku: "3013",
		name: "Adagio",
		price: 774,
		options: options.handbags,
	},
	{
		sku: "3028",
		name: "Nightingale",
		price: 484,
		options: options.handbags,
	},
	{
		sku: "3050",
		name: "Traviata",
		price: 774,
		options: options.handbags,
	},
	{
		sku: "3051",
		name: "Lucia",
		price: 516,
		options: options.handbags,
	},
	{
		sku: "3064",
		name: "Aida",
		price: 742,
		options: options.handbags,
	},
	{
		sku: "3065",
		name: "Lydia",
		price: 710,
		options: options.handbags,
	},
	{
		sku: "3085",
		name: "Anna",
		price: 581,
		options: options.handbags,
	},
	{
		sku: "3086",
		name: "Olympia",
		price: 581,
		options: options.handbags,
	},
	{
		sku: "3087",
		name: "Lisa",
		price: 581,
		options: options.handbags,
	},
	{
		sku: "3092",
		name: "Turandot",
		price: 839,
		options: options.handbags,
	},
	{
		sku: "3093",
		name: "Eva",
		price: 371,
		options: options.handbags,
	},
	{
		sku: "3097",
		name: "Judi",
		price: 645,
		options: options.handbags,
	},
	{
		sku: "3097-19",
		name: "Judi croc",
		price: 816,
		options: options.handbags,
	},
	{
		sku: "3100",
		name: "Sue",
		price: 516,
		options: options.handbags,
	},
	{
		sku: "3103",
		name: "Juliet",
		price: 935,
		options: options.handbags,
	},
	{
		sku: "3107",
		name: "Rosina",
		price: 806,
		options: options.handbags,
	},
	{
		sku: "3108",
		name: "Gloria",
		price: 935,
		options: options.handbags,
	},
	{
		sku: "3110",
		name: "Emma ( Mini Elizabeth )",
		price: 629,
		options: options.handbags,
	},
	{
		sku: "3111",
		name: "Laura ( Mini Gloria )",
		price: 887,
		options: options.handbags,
	},
	{
		sku: "3113",
		name: "Viola ( Mini Judi )",
		price: 548,
		options: options.handbags,
	},
	{
		sku: "3117",
		name: "Serena",
		price: 774,
		options: options.handbags,
	},
	{
		sku: "3118",
		name: "Sara",
		price: 603,
		options: options.handbags,
	},
	{
		sku: "3119",
		name: "Piccolo",
		price: 419,
		options: options.handbags,
	},
	{
		sku: "3121",
		name: "Natalia",
		price: 484,
		options: options.handbags,
	},
	{
		sku: "3124",
		name: "Iris",
		price: 645,
		options: options.handbags,
	},
	{
		sku: "3126",
		name: "Camelia",
		price: 645,
		options: options.handbags,
	},
	{
		sku: "3127",
		name: "Jubilee",
		price: 935,
		options: options.handbags,
	},
	{
		sku: "3128",
		name: "Andante",
		price: 774,
		options: options.handbags,
	},
	{
		sku: "3099",
		name: "Faith",
		price: 603,
		options: options.handbags,
	},
];
