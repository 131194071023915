import { NavLink, Outlet } from "react-router-dom";
import cx from "classnames";

const navItems = [
	{
		title: "Stock levels",
		path: "/cms/stock",
	},
	{
		title: "Filters",
		path: "/cms/meta",
	},
];

const AdminAppLayout: React.FC = () => {
	return (
		<div>
			<nav className="flex gap-2">
				{navItems.map((i) => (
					<NavLink
						key={i.path}
						className={(p) =>
							cx(
								"rounded bg-gray-100 px-4 py-1 text-sm",
								p.isActive && "bg-blue-100",
							)
						}
						to={i.path}
					>
						{i.title}
					</NavLink>
				))}
			</nav>
			<div className="mt-8">
				<Outlet />
			</div>
		</div>
	);
};

export default AdminAppLayout;
