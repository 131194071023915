import Vue from "vue";

// @types
import { Order, OrderItem } from "../lib/wholesaleOrder";

interface Component extends Vue {
	order: Order;
}

function getOrderItemfromCSVRow(headers: string[], cells: string[]): OrderItem {
	const item: OrderItem = {
		sku: "",
		name: "",
		options: [],
		price: 0,
		status: {},
	};

	cells.forEach((cell: string, cellIndex: number): void => {
		const header = headers[cellIndex];
		const value = cell.trim();
		if (!header || value === "" || value === "#N/A") {
			return;
		}
		switch (header) {
			case "Customer":
			case "PO #":
			case "Date":
			case "Expected Delivery":
			case "Notes":
				break;
			case "Product":
				item.name = value;
				break;
			case "Code":
				item.sku = value;
				break;
			case "Cost":
				item.price = parseFloat(value);
				break;
			default:
				if (item.options !== undefined) {
					item.options.push({ name: header, value });
				}
		}
	});

	return item;
}

function getOrderItemsfromCSV(content: string): OrderItem[] {
	const lines = content
		.split(/\n/)
		.filter((r: string): boolean => !r.startsWith(","));
	const headers =
		lines
			.splice(0, 1)[0]
			?.split(",")
			.map((c: string): string => c.trim()) || [];
	const rows = lines.map((row: string): string[] => row.split(","));
	const getOrderItem = getOrderItemfromCSVRow.bind(undefined, headers) as (
		cells: string[],
	) => OrderItem;

	return rows
		.map(getOrderItem)
		.filter((i: OrderItem): boolean => i.name !== "");
}

async function readFile(file: File): Promise<string> {
	return new Promise((resolve: (content: string) => void): void => {
		const reader = new FileReader();
		reader.addEventListener("load", (_: Event): void => {
			resolve(reader.result as string);
		});
		reader.readAsText(file);
	});
}

async function importFile(this: Component, event: Event): Promise<void> {
	const input = event.target as HTMLInputElement;
	if (input.files !== null) {
		const files = await Promise.all(Array.from(input.files).map(readFile));

		const orderItems = files.reduce(
			(p: OrderItem[], csv: string): OrderItem[] => {
				const mergedItems = p.concat(getOrderItemsfromCSV(csv));

				return mergedItems;
			},
			[],
		);

		this.order.items = this.order.items.concat(orderItems);
	}
}

export default Vue.component("order-import", {
	methods: { importFile },
	props: ["order"],
	template: `
	<form class=order-import>
		<label class=order-import__field>
			<span class=order-import__field__label>Upload CSV order file</span>
			<input class=order-import__field__input type=file
				accept=.csv
				multiple
				@change=importFile>
		</label>
	</form>
	`,
});
