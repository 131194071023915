import {
	ChartTooltip,
	RadialGauge,
	RadialGaugeArc,
	RadialGaugeLabel,
	RadialGaugeSeries,
	RadialGaugeValueLabel,
} from "reaviz";
import cx from "classnames";

type Props = {
	title: string;
	value: number;
	maxValue?: number;
	className?: string;
	strokeWidth?: number;
	fgColor?: string;
	bgColor?: string;
};

const ChartRadial: React.FC<Props> = (props) => {
	return (
		<RadialGauge
			className={cx(props.className)}
			data={[{ key: props.title, data: props.value }]}
			maxValue={props.maxValue || -1}
			center
			series={
				<RadialGaugeSeries
					valueLabel={
						<RadialGaugeValueLabel className="!fill-current !text-2xl !font-bold" />
					}
					label={
						<RadialGaugeLabel className="!fill-current !font-bold" />
					}
					outerArc={
						<RadialGaugeArc
							//disabled
							tooltip={
								<ChartTooltip
									content={() => (
										<h4 className="-mt-8 rounded-sm bg-black/50 px-2 py-1 text-xs text-white">
											Total: {props.maxValue}
										</h4>
									)}
								/>
							}
							animated={false}
							color={props.bgColor || "rgba(0,0,0,.1)"}
							cornerRadius={(props.strokeWidth || 0) / 2}
						/>
					}
					innerArc={
						<RadialGaugeArc
							disabled
							cornerRadius={(props.strokeWidth || 0) / 2}
						/>
					}
					arcWidth={props.strokeWidth}
					colorScheme={[props.fgColor || "rgba(50,255,50)"]}
				/>
			}
		/>
	);
};

export default ChartRadial;
