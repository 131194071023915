import axios, { AxiosError } from "axios";
import { endOfDay, formatISO, startOfMonth } from "date-fns";
import firebase from "firebase";
import { toast } from "react-hot-toast";
import { defer, LoaderFunction, redirect } from "react-router-dom";
import { getUserType } from "./utils";

async function getFirestoreCollection(collection: string) {
	const db = firebase.firestore().collection(collection);
	return db
		.orderBy("createdAt", "desc")
		.get()
		.then((q) => q.docs.map((d) => ({ id: d.id, ...d.data() })))
		.catch(() => []);
}

export const AppLoader: LoaderFunction = async () => {
	const userType = await getUserType();
	return { userType };
};

export const OrderLoader: LoaderFunction = async ({ params }) => {
	if (!params.id || params.id === "new") {
		return { items: [] };
	}
	const db = firebase
		.firestore()
		.collection(params.account ? "wholesale-orders" : "retail-orders")
		.doc(params.id)
		.collection("messages");
	const items = db
		.orderBy("createdAt")
		.get()
		.then((q) => q.docs.map((d) => ({ id: d.id, ...d.data() })))
		.catch(() => []);
	return defer({ items });
};

export const StockLoader: LoaderFunction = async () => {
	return defer({ items: getFirestoreCollection("stock") });
};

export const StatsLoader: LoaderFunction = async ({ request }) => {
	const params = new URL(request.url).searchParams;
	const from = params.get("from")?.toString();
	const to = params.get("to")?.toString();
	if (!from || !to) {
		const date = new Date();
		const p = new URLSearchParams({
			from: formatISO(startOfMonth(date), { representation: "date" }),
			to: formatISO(endOfDay(date), { representation: "date" }),
		});
		return redirect(`/stats?${p}`);
	}
	toast.loading("retrieving data…", { id: "stats" });
	const token = await firebase.auth().currentUser?.getIdToken();
	const result = axios
		.get("/api/stats", {
			headers: { Authorization: `Bearer ${token}` },
			params: { from, to },
		})
		.then((d) => (toast.dismiss("stats"), d))
		.catch((err: AxiosError) =>
			toast.error(err.message, { id: "stats", duration: 5000 }),
		);
	return defer({ result });
};

export const VouchersLoader: LoaderFunction = async () => {
	return defer({ items: getFirestoreCollection("vouchers") });
};

export const VoucherLoader: LoaderFunction = async ({ params }) => {
	const item = firebase
		.firestore()
		.collection("vouchers")
		.doc(params.id)
		.get()
		.then((d) => ({ id: d.id, ...d.data() }));
	return defer({ item });
};
