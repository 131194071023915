import Vue from "vue";

import { functions } from "../lib/firebase";

import "./appModal";
import "./retailOrderTransaction";

import { getOrderLastEvent, fmtOrderRef } from "@launerlondon/shared";
import type {
	RetailOrder,
	Event,
	EventType,
	EventApproved,
	EventShipped,
} from "@launerlondon/shared";
import { isCartDigital } from "@launerlondon/products";

interface Component extends Vue {
	orderId: string;
	userType: "admin" | "factory";
	notesFactory?: string;
	fraudScore?: number;
	order: RetailOrder;
	shippingInfo: EventShipped;
	showApprovalModal: boolean;
	showDispatchModal: boolean;
	showStartModal: boolean;
	showCancelModal: boolean;
	lastActionLabel: string;
}

async function pushEvent<T>(
	orderId: string,
	event: Omit<Event<T>, "date">,
): Promise<void> {
	//Date is sent as millis as https call doesn't convert date to timestamp
	await functions()
		.httpsCallable("onRetailOrderPushEventCall")({
			orderId,
			event: { ...event, date: new Date().valueOf() },
		})
		.catch((err) => {
			alert(err.message);
			throw err;
		});
}

async function approveOrder(this: Component): Promise<void> {
	await pushEvent<EventApproved>(this.orderId, {
		type: "approved",
		message: this.notesFactory,
		extra: { fraudScore: this.fraudScore },
	});
	this.showApprovalModal = false;
}

async function cancelOrder(this: Component): Promise<void> {
	await pushEvent(this.orderId, {
		type: "cancelled",
		message: this.notesFactory,
	});
	this.showCancelModal = false;
}

async function startOrder(this: Component): Promise<void> {
	await pushEvent(this.orderId, { type: "started" });
	this.showStartModal = false;
}

async function dispatchOrder(this: Component): Promise<void> {
	await pushEvent<EventShipped>(this.orderId, {
		type: "shipped",
		extra: this.shippingInfo,
	});
	this.showDispatchModal = false;
}

const modals = {
	approval: `
		<app-modal v-if=showApprovalModal @close='showApprovalModal=false'>
			<form @submit.prevent=approveOrder>
			<h2 class=app-modal__title>Send order #{{fmtOrderRef(order.ref)}} to the factory for production?</h2>
			<p>
				Please check that the payment has been received by the customer
				and that any notes and special requirements have been added to
				this order before approving.
			</p>

			<label class='app-field mt-2'>
				<span class=app-field__label>Fraud score</span>
				<input class=app-field__input v-model.number=fraudScore placeholder=45 type=number>
			</label>

			<button class=app-button type=button @click='showApprovalModal=false'>Go back</button>
			<button class=app-button type=submit>Submit</button>
			</form>
		</app-modal>
	`,
	cancel: `
		<app-modal v-if=showCancelModal @close='showCancelModal=false'>
			<form @submit.prevent=cancelOrder>
			<h2 class=app-modal__title>Cancel order #{{fmtOrderRef(order.ref)}}?</h2>
			<p>
				Please note that once an order has been cancelled, it's
				no longer possible to re-open it.
			</p>
			<label class="app-field mt-2">
				<span class="app-field__label p-0">Reason for cancelling (internal only)</span>
				<textarea class="app-field__input min-h-[100px]" v-model=notesFactory placeholder='i.e: Cancelled with order #1234 as a replacement'/>
			</label>
			<button class=app-button type=button @click='showCancelModal=false'>Go back</button>
			<button class=app-button type=submit>Submit</button>
			</form>
		</app-modal>
	`,
	dispatch: `
		<app-modal v-if=showDispatchModal @close='showDispatchModal=false'>
			<form @submit.prevent=dispatchOrder>
				<h2 class=app-modal__title>Mark order #{{fmtOrderRef(order.ref)}} as completed and dispatched?</h2>
				<template v-if='isDigitalOnly'>
					<p>This order contains only digital items, mark it as completed?</p>
				</template>
				<template v-else>
					<p>
						Upon submit, an email will be sent to the customer with their
						tracking number and other details.
					</p>
					<div class=app-field__group>
						<label class=app-field>
							<span class=app-field__label>Tracking number</span>
							<input class=app-field__input v-model=shippingInfo.trackingNumber required>
						</label>
						<label class=app-field>
							<span class=app-field__label>Shipping method</span>
							<select class=app-field__input v-model=shippingInfo.company required>
								<option>DHL Parcel UK</option>
								<option>Royal Mail</option>
								<option>DSV</option>
							</select>
						</label>
					</div>
					<p>
						<small>
							Full order details will be stored in the cloud for future
							reference.
						</small>
					</p>
				</template>
				<button type=button class=app-button @click='showDispatchModal=false'>Go back</button>
				<button type=submit class=app-button>Submit</button>
			</form>
		</app-modal>
	`,
	start: `
		<app-modal v-if=showStartModal @close='showStartModal=false'>
			<form @submit.prevent=startOrder>
			<h2 class=app-modal__title>Start order #{{fmtOrderRef(order.ref)}} production</h2>
			<p>Mark the start of the production for this order?</p>
			<button class=app-button type=button @click='showStartModal=false'>Go back</button>
			<button class=app-button type=submit>Start</button>
			</form>
		</app-modal>
	`,
	transaction: `
		<app-modal v-if=showTransactionModal @close='showTransactionModal=false'>
			<retail-order-transaction :order=order @close='showTransactionModal=false'>
			</retail-order-transaction>
		</app-modal>
	`,
};

export default Vue.component("retail-order-action", {
	data() {
		return {
			orderId: this.$route.params.orderId,
			fraudScore: undefined,
			notesFactory: undefined,
			shippingInfo: {},
			showApprovalModal: false,
			showCancelModal: false,
			showDispatchModal: false,
			showStartModal: false,
			showTransactionModal: false,
		};
	},
	computed: {
		shouldShowComponent(this: Component): boolean {
			const event: Event = getOrderLastEvent(this.order);
			const { type } = event;
			if (this.userType === "admin") {
				return (
					type === "pending" ||
					type === "placed" ||
					type === "charged" ||
					type === "failed" ||
					type === "approved" ||
					type === "started"
				);
			}
			if (this.userType === "factory") {
				return type === "approved" || type === "started";
			}

			return false;
		},
		shouldShowCancelAction(this: Component): boolean {
			const event: Event = getOrderLastEvent(this.order);
			return (
				this.userType === "admin" &&
				(event.type === "approved" || event.type === "started")
			);
		},
		lastActionLabel(this: Component): EventType {
			return getOrderLastEvent(this.order).type;
		},
		isDigitalOnly(this: Component) {
			return isCartDigital(this.order.items);
		},
	},
	methods: {
		fmtOrderRef,
		approveOrder,
		cancelOrder,
		dispatchOrder,
		startOrder,
	},
	props: ["order", "userType"],
	template: `
	<div class=retail-order-action v-if=shouldShowComponent>
		<div class='retail-order-action__container pt-2 pb-4'>
			<h4 class=retail-order-action__title>Next action</h4>
			<button class=app-button v-if='lastActionLabel==="pending"' @click='showTransactionModal=true'>Push order</button>
			<button class=app-button v-if='lastActionLabel==="placed"' @click='showTransactionModal=true'>Add payment info</button>
			<button class=app-button v-if='lastActionLabel==="charged"' @click='showCancelModal=true'>Cancel order</button>
			<button class=app-button v-if='lastActionLabel==="charged"' @click='showApprovalModal=true'>Approve production</button>
			<button class=app-button v-if='lastActionLabel==="failed"' @click='showTransactionModal=true'>Retry payment</button>
			<button class=app-button v-if='shouldShowCancelAction' @click='showCancelModal=true'>Cancel order</button>
			<button class=app-button v-if='userType === "admin" && lastActionLabel==="approved" && isDigitalOnly' @click='showDispatchModal=true'>Complete order</button>
			<button class=app-button v-if='userType === "factory" && lastActionLabel==="approved" && !isDigitalOnly' @click='showStartModal=true'>Start production</button>
			<button class=app-button v-if='userType === "factory" && lastActionLabel==="started"' @click='showDispatchModal=true'>Mark as dispatched</button>
		</div>

		${modals.approval}
		${modals.transaction}
		${modals.start}
		${modals.dispatch}
		${modals.cancel}
	</div>
	`,
});
