import Vue from "vue";
import { fmtCurrency, uuid } from "../lib/utils";

// tslint:disable no-import-side-effect
import "./wholesaleOrderItemListStatus";
import "./wholesaleOrderItemNew";

// @types
import { OrderItem } from "../lib/wholesaleOrder";
interface Component extends Vue {
	selectedItem?: OrderItem;
	items: OrderItem[];
}

const computed = {
	completedItems(this: Component): number {
		return this.items.filter((item: OrderItem): boolean => {
			const isCompleted = item.status.hasOwnProperty("complete");

			return isCompleted;
		}).length;
	},
};

function removeItem(this: Component, item: OrderItem): void {
	const index = this.items.findIndex((i: OrderItem): boolean => i === item);
	if (index >= 0 && confirm(`Remove '${item.name}'?`)) {
		this.items.splice(index, 1);
	}
}

function onOrderItemUpdate(this: Component): void {
	this.selectedItem = undefined;
}

export default Vue.component("order-item-list", {
	computed,
	data(): object {
		return { selectedItem: undefined };
	},
	methods: { fmtCurrency, onOrderItemUpdate, removeItem, uuid },
	props: ["items", "userType"],
	template: `
	<div class=order-item-list>
		<header class=order-item-list__counter>
			<span class=order-item-list__counter__total>
				Total items: {{items.length}}
			</span>
			<span class=order-item-list__counter__complete>
				({{completedItems}} complete)
			</span>
		</header>
		<transition name=order-item-list__modal>
			<div class=order-item-list__modal v-show=selectedItem>
				<div class=order-item-list__modal__container>
					<i class=order-item-list__modal__icon_close @click='selectedItem=undefined'></i>
					<order-item-new
						v-if="userType==='admin'"
						:feed=selectedItem
						@update=onOrderItemUpdate>
					</order-item-new>
				</div>
			</div>
		</transition>
		<table class=order-item-list__table>
			<tr class=order-item-list__header>
				<th class=order-item-list__header__cell_spacer></th>
				<th class=order-item-list__header__cell>SKU</th>
				<th class=order-item-list__header__cell>Product name</th>
				<th class=order-item-list__header__cell>Options</th>
				<th class=order-item-list__header__cell>Cut</th>
				<th class=order-item-list__header__cell>Prepare</th>
				<th class=order-item-list__header__cell>Complete</th>
				<th class='order-item-list__header__cell order-item-list__header__cell_price'>
					Price
				</th>
				<th class=order-item-list__header__cell_spacer></th>
			</tr>
			<tr v-for='item in items' class=order-item-list__row :key=uuid()>
				<td class=order-item-list__row__cell_spacer></td>
				<td class=order-item-list__row__cell>{{item.sku}}</td>
				<td class=order-item-list__row__cell>{{item.name}}</td>
				<td class='order-item-list__row__cell order-item-list__row__cell_options'>
					<span v-for='option in item.options'
						:key=option.name
						class=order-item-list__row__cell__option>
						<template v-if="option.value">
							<b>{{option.name}}:</b> {{option.value}}
						</template>
					</span>
				</td>
				<td class='order-item-list__row__cell order-item-list__row__cell_status'>
					<order-item-list-status :status=item.status step=cut :user-type=userType>
					</order-item-list-status>
				</td>
				<td class='order-item-list__row__cell order-item-list__row__cell_status'>
					<order-item-list-status :status=item.status step=prepare :user-type=userType>
					</order-item-list-status>
				</td>
				<td class='order-item-list__row__cell order-item-list__row__cell_status'>
					<order-item-list-status :status=item.status step=complete :user-type=userType>
					</order-item-list-status>
				</td>
				<td class='order-item-list__row__cell order-item-list__row__cell_price'>
					{{fmtCurrency(item.price)}}
				</td>
				<td class='order-item-list__row__cell order-item-list__row__cell_spacer'>
					<i v-if="userType==='admin'"
						class='order-item-list__icon order-item-list__icon_trash'
						title='Remove order item'
						@click=removeItem(item)>
					</i>
					<i v-if="userType==='admin'"
						class='order-item-list__icon order-item-list__icon_edit'
						title='Edit order item'
						@click="selectedItem=item">
					</i>
				</td>
			</tr>
			<tr v-if='items.length === 0' class='order-item-list__row order-item-list__row_empty'>
				<td class=order-item-list__row__cell_spacer></td>
				<td colspan=7 class='order-item-list__row__cell order-item-list__row_empty__cell'>No products on this order yet</td>
				<td class=order-item-list__row__cell_spacer></td>
			</tr>
		</table>
	</div>
	`,
});
