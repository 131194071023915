import { isVoucherCreditOption } from "@launerlondon/products";
import {
	Amount,
	Currency,
	Item,
	ItemOption,
	RetailOrder,
	Total as RetailOrderTotal,
	calculateTaxRate,
	fmtPrice,
	getItemOptionTotal,
	getItemPrice,
	getOrderTotal,
	getTotalTax,
	needsTaxAdded,
	roundDecimals,
} from "@launerlondon/shared";
import Vue from "vue";

interface Component extends Vue {
	order: RetailOrder;
	editMode: boolean;
	tax: { error: boolean; auto: number; real: number; subtotal: number };
	taxRate: number;
	currency: Currency;
}

function getOrderCurrencies(order: RetailOrder): Currency[] {
	const ccy = Object.keys(order.items[0].price) as Currency[];
	if (order.customer.address.country === "GB") {
		return ccy.filter((c) => c !== "base");
	}
	return ccy;
}

function getProductPrice(this: Component, product: Item): string {
	const value =
		getItemPrice(product, this.order._index.roundFactor)[this.currency] ||
		0;
	return fmtPrice(value, this.currency);
}

function isLegacyOrder(order: RetailOrder) {
	return (
		import.meta.env.PROD &&
		order.ref < 14042 &&
		order.customer.address.country !== "CN"
	);
}

function getOptionPrice(
	this: Component,
	option: ItemOption,
	productPrice: Amount,
): string {
	const value =
		getItemOptionTotal(option, productPrice, this.order._index.roundFactor)[
			this.currency
		] || 0;
	return (value > 0 ? "+" : "") + fmtPrice(value, this.currency);
}
function getTotal(
	this: Component,
	key: keyof RetailOrderTotal,
	setCurrency?: Currency,
): string {
	const ccy = setCurrency || this.currency;
	const totals = getOrderTotal(this.order, this.order._index.roundFactor);
	return fmtPrice(totals[key][ccy] || 0, ccy, "code");
}
function getTax(
	order: RetailOrder,
	currency: Currency,
): { error: boolean; auto: number; real: number; subtotal: number } {
	const orderTotal = getOrderTotal(order, order._index.roundFactor);
	const legacy = isLegacyOrder(order);
	const country = legacy ? "GB" : order.customer.address.country;
	const state = legacy ? undefined : order.customer.address.state;
	const auto = getTotalTax(orderTotal, country, state)[currency] || 0;
	let real = auto;
	let sub = order.total.subtotal[currency] || 0;

	// verify tax amount
	if (needsTaxAdded(country)) {
		const { gross, subtotal, shipping } = order.total;
		const oSubtotal = subtotal[currency] || 0;
		const oShipping = shipping[currency] || 0;
		const oGross = gross[currency] || 0;
		real = roundDecimals(oGross - oSubtotal - oShipping);
		sub = oSubtotal - (auto - real);
	}
	return {
		error: legacy ? false : auto !== real,
		auto,
		real,
		subtotal: sub,
	};
}

export default Vue.component("retail-order-summary", {
	data() {
		const order = this.order as RetailOrder;
		const { country } = order.customer.address;
		const paymentCurrency = order.payment.currency;
		const currency = /gbp|eur|usd/.test(paymentCurrency)
			? paymentCurrency
			: "gbp";
		const taxRate = isLegacyOrder(order)
			? 20
			: calculateTaxRate(order.total, country);
		return { taxRate, currency, country };
	},
	computed: {
		tax(this: Component) {
			return getTax(this.order, this.currency);
		},
		currencies(this: Component) {
			return getOrderCurrencies(this.order);
		},
		isTaxReport(this: Component) {
			return this.currency === ("base" as "gbp");
		},
	},
	methods: {
		getProductPrice,
		getOptionPrice,
		getTotal,
		fmtPrice,
		isVoucherCreditOption,
	},
	props: ["order", "editMode"],
	template: `
	<table class=retail-order-summary>
		<thead>
		<tr>
			<td colspan="3" class=retail-order-summary__header>
				<label>
					<span> Currency</span>
					<select v-model=currency class='p-1 rounded ml-2'>
						<template v-for='c in currencies'>
							<option class='border-0' :value=c>{{c === 'base' || c === 'gbp' &&  country === 'GB' ? 'GBP (Tax Report)' : c.toUpperCase()}}</option>
						</template>
					</select>
				</label>
			</td>
		</tr>
		</thead>
		<template v-for='product in order.items'>
			<tbody class=retail-order-summary__item>
				<tr class=retail-order-summary__item__attr>
					<th class=retail-order-summary__item__key>Product</th>
					<td class=retail-order-summary__item__value>{{product.name}}</td>
					<td class=retail-order-summary__item__price>{{getProductPrice(product)}}</td>
				</tr>
				<tr class=retail-order-summary__item__attr>
					<th class=retail-order-summary__item__key>Code</th>
					<td class=retail-order-summary__item__value>{{product.sku}}</td>
					<td class=retail-order-summary__item__price></td>
				</tr>
				<tr class=retail-order-summary__item__attr v-for='option in product.options'>
					<th class=retail-order-summary__item__key>{{option.name}}</th>
					<td class=retail-order-summary__item__value>
						<span v-if=!editMode>
							{{option.value}}
						</span>
						<span v-if=editMode>
							<input v-model=option.value style="font-size:0.875em"/>
						</span>
					</td>
					<td class=retail-order-summary__item__price>
						<template v-if=option.multiplier>{{getOptionPrice(option,product.price)}}</template>
						<router-link
							v-if='isVoucherCreditOption(option) && product.extra'
							:to="'/vouchers/'+ product.extra.id"
							class='underline'>
							view voucher
						</router-link>
					</td>
				</tr>
			</tbody>
			<tr class=retail-order-summary__spacer></tr>
		</template>
		<tfoot>
			<tr>
				<td>&nbsp</td>
				<th class=retail-order-summary__item__key>Subtotal</th>
				<td class=retail-order-summary__item__price>
					<div v-if=tax.error>
						<small>
							({{fmtPrice(tax.subtotal, currency, "code")}})
						</small>
						<span style='color:red'>{{getTotal('subtotal')}}</span>
					</div>
					<div v-if=!tax.error>
						{{getTotal('subtotal')}}
					</div>
				</td>
			</tr>
			<tr>
				<td>&nbsp</td>
				<td class=retail-order-summary__item__key>Tax and duties ({{taxRate}}%)</td>
				<td class=retail-order-summary__item__price>
					<div v-if=tax.error>
						<small>
							({{fmtPrice(tax.auto, currency, "code")}})
						</small>
						<span style='color:red'>
							{{fmtPrice(tax.real, currency, "code")}}
						</span>
					</div>
					<div v-if=!tax.error>
						{{fmtPrice(tax.auto, currency, "code")}}
					</div>
				</td>
			</tr>
			<tr >
				<td>&nbsp</td>
				<td class=retail-order-summary__item__key>Shipping cost</td>
				<td class=retail-order-summary__item__price>{{getTotal('shipping')}}</td>
			</tr>
			<tr>
				<td>&nbsp</td>
				<th class=retail-order-summary__item__key>Total</th>
				<td class=retail-order-summary__item__price>{{getTotal('gross')}}</td>
			</tr>
			<tr v-if="order.vouchers?.length">
				<td>&nbsp</td>
				<th class=retail-order-summary__item__key>
					<div>Discount</div>
					<div v-for='voucher in order.vouchers' class='flex gap-2'>
						<router-link :to="'/vouchers/'+ voucher.id" class='font-normal underline text-blue-500' >voucher</router-link>
					</div>
				</th>
				<td class=retail-order-summary__item__price>-{{getTotal('discount')}}</td>
			</tr>
			<tr v-if="order.events.some(e => e.type === 'charged')">
				<td>&nbsp</td>
				<th class=retail-order-summary__item__key>Total paid</th>
				<td class=retail-order-summary__item__price>{{getTotal('paid', order.payment.currency)}}</td>
			</tr>
		</tfoot>
	</table>
	`,
});
