import { fmtOrderRef } from "@launerlondon/shared";
import Vue from "vue";
import VueRouter from "vue-router";
import "./components/appAuth";
import "./components/appBreadcrumb";
import "./components/appHeader";
import "./components/appPopupMenu";
import "./components/appShell";
import "./components/appSignin";
import ReactWrapper from "./components/reactWrapper";
import RetailOrderList from "./components/retailOrderList";
import RetailOrderView from "./components/retailOrderView";
import AccountEdit from "./components/wholesaleAccountEdit";
import AccountList from "./components/wholesaleAccountList";
import AccountView from "./components/wholesaleAccountView";
import OrderEdit from "./components/wholesaleOrderEdit";
import "./index.css";

import { watch as accountWatch } from "./lib/wholesaleAccountWatcher";

import type { Route, RouteConfig } from "vue-router";
import type { AuthState, User } from "./components/appAuth";
import type { Account } from "./lib/wholesaleAccount";

interface ComponentData {
	account: Account | Record<string, unknown>;
	accounts: Account[];
	authState: AuthState;
}
interface Component extends ComponentData, Vue {}

const version = String(import.meta.env.VITE_PKG_VERSION);
const environment = String(import.meta.env.MODE);
console.info(`v${version} (${environment})`);

Vue.use(VueRouter);

const el = "main";

const data: ComponentData = {
	account: {},
	accounts: [],
	authState: {
		authorized: false,
		user: {} as User,
		userType: undefined,
		roles: [],
	} as AuthState,
};

const watch = {
	"authState.authorized"(this: Component, n: boolean, o: boolean): void {
		if (n !== o && n) {
			accountWatch(this.accounts);
		}
	},
};

function passthroughAccount(route: Route): Record<string, unknown> {
	const accountId: string = route.params.accountId || "0000";
	const account = {};
	/*
	 * If accounts hasn't finish loading, return empty
	 * object since it will be retried once loaded
	 */
	if (data.accounts.length === 0) {
		return { account };
	}
	const acc = data.accounts.find((a: Account) => a.id === accountId);
	if (acc === undefined) {
		component.$router.push("/accounts").catch((err) => console.error(err));

		return { account };
	}

	component.account = acc;

	return {
		account: acc,
		userType: data.authState.userType,
		roles: data.authState.roles,
	};
}

const wholesaleRoutes: RouteConfig[] = [
	{
		path: "/accounts",
		name: "accountList",
		component: AccountList,
		meta: {
			section: "Wholesale accounts",
			userType: data.authState.userType,
			roles: data.authState.roles,
		},
		props() {
			if ("id" in component.account) {
				component.account = {};
			}

			return {
				accounts: data.accounts,
				userType: data.authState.userType,
				roles: data.authState.roles,
			};
		},
	},
	{
		path: "/accounts/new",
		name: "accountNew",
		component: AccountEdit,
		meta: {
			section: "Wholesale accounts",
			sectionPath: "/accounts",
			pageTitle: "Create Account",
		},
		props: {},
	},
	{
		path: "/accounts/:accountId/edit",
		component: AccountEdit,
		meta: {
			section: "Wholesale accounts",
			sectionPath: "/accounts",
			pageTitle: "Edit Account",
		},
		props(route: Route) {
			return { ...passthroughAccount(route) };
		},
	},
	{
		path: "/accounts/:accountId",
		name: "accountView",
		component: AccountView,
		meta: {
			section: "Wholesale accounts",
			sectionPath: "/accounts",
		},
		props(route: Route) {
			return { ...passthroughAccount(route) };
		},
	},
	{
		path: "/accounts/:accountId/orders/:orderId",
		name: "orderView",
		component: OrderEdit,
		meta: {
			section: "Wholesale accounts",
			sectionPath: "/accounts",
		},
		props(route: Route) {
			return { ...passthroughAccount(route) };
		},
	},
];

const retailRoutes: RouteConfig[] = [
	{
		path: "/retail/orders",
		name: "retailOrderList",
		component: RetailOrderList,
		meta: {
			section: "Online Orders",
			sectionPath: "/retail/orders",
		},
		props() {
			if ("id" in component.account) {
				component.account = {};
			}

			return {
				userType: data.authState.userType,
				roles: data.authState.roles,
			};
		},
	},
	{
		path: "/retail/orders/:orderId/:orderRef",
		name: "retailOrderView",
		component: RetailOrderView,
		meta: {
			section: "Online Orders",
			sectionPath: "/retail/orders",
		},
		props() {
			if ("id" in component.account) {
				component.account = {};
			}

			return {
				userType: data.authState.userType,
				roles: data.authState.roles,
			};
		},
	},
];

const cmsRoutes: RouteConfig[] = [
	{
		path: "/cms/:path(.*)?",
		component: ReactWrapper,
		meta: {
			section: "CMS",
			sectionPath: "/cms",
		},
		props() {
			return {
				userType: data.authState.userType,
				roles: data.authState.roles,
			};
		},
	},
];

const statsRoutes: RouteConfig[] = [
	{
		path: "/stats/:path(.*)?",
		component: ReactWrapper,
		meta: {
			section: "Stats",
			sectionPath: "/stats",
		},
		props() {
			return {
				userType: data.authState.userType,
				roles: data.authState.roles,
			};
		},
	},
];
const vouchersRoutes: RouteConfig[] = [
	{
		path: "/vouchers/:path(.*)?",
		component: ReactWrapper,
		meta: {
			section: "Online Vouchers",
			sectionPath: "/vouchers",
		},
		props() {
			return {
				userType: data.authState.userType,
				roles: data.authState.roles,
			};
		},
	},
];

const rootRoute: RouteConfig = {
	path: "/",
	component: Vue.component("app-root", {
		props: ["userType", "roles"],
		mounted() {
			if (this.$props.roles.includes("retail")) {
				this.$router.replace("/retail/orders");
				return;
			}
			if (this.$props.roles.includes("wholesale")) {
				this.$router.replace("/accounts");
				return;
			}
			if (this.$props.roles.includes("stats")) {
				this.$router.replace("/stats");
				return;
			}
		},
		template: `<div></div>`,
	}),
	meta: {
		section: "Stats",
		sectionPath: "/stats",
	},
	props() {
		return {
			userType: data.authState.userType,
			roles: data.authState.roles,
		};
	},
};

const router = new VueRouter({
	mode: "history",
	routes: [
		rootRoute,
		...wholesaleRoutes,
		...retailRoutes,
		...vouchersRoutes,
		...statsRoutes,
		...cmsRoutes,
	],
});

router.beforeEach((to: Route, _: Route, next: () => void): void => {
	const area = to.path.split("/")[1] || "";
	document.documentElement.setAttribute("data-area", area);
	const routeMeta = to.meta as { pageTitle: string; section: string };
	if (to.name === "orderView") {
		routeMeta.pageTitle =
			to.params.orderId === "new" ? "Create order" : "Edit order";
	}
	if (to.name === "retailOrderView") {
		routeMeta.pageTitle = `Order #${fmtOrderRef(
			parseInt(to.params.orderRef || "0"),
		)}`;
	}
	next();
});

// link react-router-dom by triggering method defined on @launerlondon/orders-router
router.afterEach((to) => {
	if ("__nav" in self) (self["__nav"] as any)(to.fullPath, { replace: true });
});

const component: Component = new Vue({ data, el, watch, router });
