import { convertOrdersToCSV } from "@launerlondon/orders-utils";
import {
	Currency,
	EventType,
	RetailOrder,
	fmtOrderRef,
	fmtPrice,
	getOrderTotal,
	mergeAmounts,
	toDate,
} from "@launerlondon/shared";
import Vue from "vue";
import { fmtCurrency } from "../lib/utils";
import { DocChangeBatchResult, QueryFilter, watch } from "../lib/watchDb";
import "./retailOrderListFilter";
import { Filter } from "./retailOrderListFilter";
import "./retailOrderStatus";

interface Component extends Vue {
	orders: RetailOrder[];
}

let listener: undefined | (() => void);

function getPaymentStatus(order: RetailOrder): EventType {
	if (order.events.some((e) => e.type === "charged")) {
		return "charged";
	}
	if (order.events.some((e) => e.type === "failed")) {
		return "failed";
	}

	return "pending";
}

function fmtOrderDate(date: Date): string {
	return `${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`;
}

function getOrderUrl(orderId: string, orderRef: string): string {
	return `/retail/orders/${orderId}/${orderRef}`;
}

function onOrderClick(
	this: Component,
	orderId: string,
	orderRef: string,
): void {
	this.$router
		.push({
			name: "retailOrderView",
			params: { orderId, orderRef },
		})
		.catch((err) => console.error(err));
}

function onChange(
	this: Component,
	queryFilters: QueryFilter[],
	filterName: Filter,
): void {
	if (listener !== undefined) {
		this.$set(this.$data, "orders", []);
		listener();
	}

	listener = watch({
		collection: "retail-orders",
		where: queryFilters,
		orderBy:
			filterName === "order-number"
				? undefined
				: ["_index.createdAt", "desc"],
		onBatch: (docs: DocChangeBatchResult[]): void => {
			docs.forEach((change: DocChangeBatchResult) => {
				if (change.changeType === "added") {
					const doc = change.doc as RetailOrder;
					if (docs.length === 1) {
						this.orders.splice(0, 0, doc);
					} else {
						this.orders.push(doc);
					}
				}
			});
			this.orders.sort((a: RetailOrder, b: RetailOrder): number => {
				const aDate: Date = toDate(a.events[0].date);
				const bDate: Date = toDate(b.events[0].date);
				const isNewer = aDate.valueOf() > bDate.valueOf();

				return isNewer ? -1 : 1;
			});
		},
	});
}

function getOrderCSV(this: Component) {
	const csv = convertOrdersToCSV(this.orders);
	const encodedUri = encodeURI(csv);
	const link = document.createElement("a");
	link.setAttribute("href", encodedUri);
	link.setAttribute("download", "launer-retail-orders.csv");
	document.body.appendChild(link);
	link.click();
}

function getOrdersTotalCharged(this: Component, currency: Currency): string {
	const orders = this.orders.filter((o) => {
		const matchStatus = o.events.some((e) => e.type === "charged");
		const matchCurrency = o.payment.currency === currency;
		return matchStatus && matchCurrency;
	});
	const amount = mergeAmounts(
		orders.map((o) => getOrderTotal(o, o._index.roundFactor).gross),
	);
	return fmtCurrency(amount[currency]);
}

function getOrderTotalPrice(this: Component, order: RetailOrder): string {
	const currency = order.payment.currency;
	const total =
		getOrderTotal(order, order._index.roundFactor).gross[currency] || 0;
	return fmtPrice(total, currency, "code");
}

export default Vue.component("retail-order-list", {
	methods: {
		getOrderCSV,
		fmtCurrency,
		fmtPrice,
		fmtOrderDate,
		fmtOrderRef,
		getPaymentStatus,
		onChange,
		onOrderClick,
		getOrderUrl,
		getOrdersTotalCharged,
		getOrderTotalPrice,
	},
	data() {
		return { orders: [] };
	},
	template: `
	<section class=retail-order-list>
		<header class=retail-order-list__header>
			<div class=retail-order-list__total-sales>
				<div class=retail-order-list__total-sales__amount>
					<div class=retail-order-list__total-sales__amount__currency>
						<span class=retail-order-list__total-sales__amount__value>{{getOrdersTotalCharged('gbp')}}</span>
						<span class=retail-order-list__total-sales__amount__code>GBP</span>
					</div>
					<div class='retail-order-list__total-sales__amount__currency'>
						<span class=retail-order-list__total-sales__amount__value>{{getOrdersTotalCharged('usd')}}</span>
						<span class=retail-order-list__total-sales__amount__code>USD</span>
					</div>
					<div class='retail-order-list__total-sales__amount__currency'>
						<span class=retail-order-list__total-sales__amount__value>{{getOrdersTotalCharged('eur')}}</span>
						<span class=retail-order-list__total-sales__amount__code>EUR</span>
					</div>
					<div class='retail-order-list__total-sales__amount__currency'>
						<span class=retail-order-list__total-sales__amount__value>{{getOrdersTotalCharged('cny')}}</span>
						<span class=retail-order-list__total-sales__amount__code>CNY</span>
					</div>
				</div>
			</div>
			<div class=retail-order-list__filter>
				<retail-order-list-filter @change=onChange></retail-order-list-filter>
				<div class=retail-order-list__action>
					<button class=retail-order-list__button_csv @click=getOrderCSV>Export CSV</button>
				</div>
			</div>
		</header>
		<table class=order-item-list__table>
			<tr class=order-item-list__header>
				<th class=order-item-list__header__cell_spacer></th>
				<th class=order-item-list__header__cell>Order No.</th>
				<th class=order-item-list__header__cell>Date</th>
				<th class=order-item-list__header__cell>Customer name</th>
				<th class=order-item-list__header__cell>Total value</th>
				<th class=order-item-list__header__cell>Payment status</th>
				<th class=order-item-list__header__cell>P. method</th>
				<th class=order-item-list__header__cell>Country</th>
				<th class=order-item-list__header__cell>Fulfilment status</th>
				<th class=order-item-list__header__cell_spacer></th>
			</tr>
			<tr v-for='order in orders' class=order-item-list__row :key=order.id @click.prevent=onOrderClick(order.id,order.ref)>
				<td class=order-item-list__row__cell_spacer></td>
				<td class=order-item-list__row__cell>
				  <a :href='getOrderUrl(order.id, order.ref)' target='_blank' @click.stop=''>{{fmtOrderRef(order.ref)}}</a>
				</td>
				<td class=order-item-list__row__cell>{{order.events[0].date.toDate().toLocaleDateString()}}</td>
				<td class=order-item-list__row__cell>{{order.customer.name}}</td>
				<td class=order-item-list__row__cell>{{ getOrderTotalPrice(order) }}</td>
				<td :class='["order-item-list__row__cell", "order-item-list__row__cell_payment-status", "order-item-list__row__cell_payment-status_" + getPaymentStatus(order)]'>
					{{getPaymentStatus(order)}}
				</td>
				<td class='order-item-list__row__cell order-item-list__row__cell_payment-type'>{{order.payment.type}}</td>
				<td class='order-item-list__row__cell order-item-list__row__cell_country'>{{order.customer?.address?.country}}</td>
				<td class='order-item-list__row__cell order-item-list__row__cell_fullfilment-status'>
					<retail-order-status :order=order mini=true>
					</retail-order-status>
				</td>
				<td class=order-item-list__row__cell_spacer></td>
				</a>
			</tr>
		</table>
	</section>
	`,
});
