import Vue from "vue";

export default Vue.component("app-modal", {
	methods: {
		enter(this: Vue): void {
			const onModalKeyUp = (event: KeyboardEvent): void => {
				if (event.key === "Escape") {
					this.$emit("close");
					removeEventListener("keyup", onModalKeyUp);
				}
			};
			addEventListener("keyup", onModalKeyUp);
		},
	},
	template: `
	<transition name=fade @after-enter=enter>
		<div class=app-modal>
			<div class=app-modal__container>
				<div class=app-modal__content>
					<div class=app-modal__close @click="$emit('close')"></div>
					<slot></slot>
				</div>
			</div>
		</div>
	</transition>
	`,
});
