import Vue from "vue";

import { AccountAddress } from "../lib/wholesaleAccount";

interface Component extends Vue {
	addresses: AccountAddress[];
}

function removeAddress(this: Component, address: AccountAddress): void {
	const index = this.addresses.findIndex((a: AccountAddress): boolean => {
		const postCodeMatch = a.postcode === address.postcode;

		return postCodeMatch;
	});
	if (index >= 0) {
		this.addresses.splice(index, 1);
	}
}

export default Vue.component("account-address-list", {
	methods: { removeAddress },
	props: {
		addresses: {
			default(): AccountAddress[] {
				return [];
			},
		},
	},
	template: `
	<ul class=account-address-list>
		<li class=account-address-list__item v-for='address in addresses' :key=address.postcode>
			<span class='account-address-list__item__part account-address-list__item__part_line1'>{{address.line1}}</span>
			<span class='account-address-list__item__part account-address-list__item__part_line2'>{{address.line2}}</span>
			<span class='account-address-list__item__part account-address-list__item__part_city'>{{address.city}}</span>
			<span class='account-address-list__item__part account-address-list__item__part_region'>{{address.region}}</span>
			<span class='account-address-list__item__part account-address-list__item__part_postcode'>{{address.postcode}}</span>
			<span class='account-address-list__item__part account-address-list__item__part_country'>{{address.country}}</span>
			<button type=button @click=removeAddress(address) class=account-address-list__item__remove>&cross;</button>
		</li>
		<li v-if='addresses.length === 0'>No addresses registered so far</li>
	</ul>
	`,
});
