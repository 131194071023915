import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { useLocalization } from "@launerlondon/l10n";
import { DateRange, ChartRadial } from "@launerlondon/orders-components";
import cx from "classnames";
import { formatISO } from "date-fns";
import { Suspense, useState } from "react";
import {
	Await,
	useAsyncValue,
	useLoaderData,
	useSubmit,
} from "react-router-dom";

type DataKey =
	| "bookmarks"
	| "countries"
	| "customers"
	| "products"
	| "profiles"
	| "swatches"
	| "wishlists"
	| "payments";

type Aggr = { name: string; count: number; detail?: Aggr[] };
type Data = { count: number; aggr: Aggr[] };

type CardProps = {
	title: string;
	data: Data;
	className?: string;
};

const CardCount: React.FC<CardProps> = (props) => {
	const value = props.data.aggr.find((a) => a.name === "in")?.count || 0;
	const maxValue = props.data.count || value;
	return (
		<div
			className={cx(
				"flex aspect-square flex-col items-center justify-center",
				"rounded bg-gray-50 p-4 uppercase",
				props.className,
			)}
		>
			<ChartRadial
				className="text-gray-700"
				title={props.title}
				value={value}
				maxValue={maxValue}
				fgColor="#267DE9"
				bgColor="#EAF1F4"
				strokeWidth={10}
			/>
			<h2 className="hidden font-light uppercase">{props.title}</h2>
			<h3 className="hidden text-5xl">
				{props.data.count.toString().padStart(3, "0") || "000"}
			</h3>
		</div>
	);
};
function getLocaleTitleId(title: string, value: string) {
	return /countries/i.test(title)
		? `country-${value}`
		: /products|wishlist/i.test(title)
		? `sku-${value.padStart(4, "0")}`
		: value;
}

const CardTop: React.FC<CardProps> = ({ data, ...props }) => {
	const defaultLimit = 5;
	const { l10n } = useLocalization();
	const [limit, setLimit] = useState(defaultLimit);
	const [detailViewIndex, setIsDetailViewIndex] = useState<number>();
	const isDetailView = detailViewIndex !== undefined;
	const aggr = isDetailView
		? data.aggr[detailViewIndex]?.detail || []
		: data.aggr;
	const items = aggr.slice(0, limit);
	const showLimit = aggr.length > defaultLimit;
	const titleLabel = isDetailView
		? l10n.getString(
				getLocaleTitleId(
					props.title,
					data.aggr[detailViewIndex]?.name || "undefined",
				),
		  )
		: props.title;
	return (
		<div className={cx("rounded bg-gray-50 p-4", props.className)}>
			<header className="mb-2 flex items-center justify-between">
				{isDetailView && (
					<button onClick={() => setIsDetailViewIndex(undefined)}>
						<ArrowUturnLeftIcon className="mr-2 h-3 w-3" />
					</button>
				)}
				<h2
					className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap font-bold"
					title={titleLabel}
				>
					{titleLabel}
				</h2>
				{showLimit && (
					<button
						className="text-xs text-gray-400 underline"
						onClick={() =>
							setLimit((v) =>
								Number.isFinite(v) ? Infinity : defaultLimit,
							)
						}
					>
						{Number.isFinite(limit) ? "Show more" : "Show less"}
					</button>
				)}
			</header>
			{!data?.count && (
				<p className="text-sm text-gray-500">No results found</p>
			)}
			<ul className="max-h-80 divide-y divide-dotted overflow-auto text-sm">
				{items?.map((i, idx) => {
					const label = isDetailView
						? i.name
						: l10n.getString(getLocaleTitleId(props.title, i.name));

					return (
						<li
							key={i.name}
							className="flex justify-between gap-2 py-1"
						>
							<h3
								className={cx(
									"overflow-hidden text-ellipsis whitespace-nowrap",
									i.detail && "cursor-pointer underline",
								)}
								onClick={() => {
									setIsDetailViewIndex(idx);
								}}
								title={label}
							>
								{label}
							</h3>
							<b>{i.count}</b>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

const Cards: React.FC = () => {
	const { data } = useAsyncValue() as { data: Record<DataKey, Data> };
	return (
		<div className="grid grid-cols-3 gap-4">
			<CardCount title="Users" data={data.profiles} />
			<CardCount title="Wishlists Interactions" data={data.wishlists} />
			<div className="col-span-3 grid grid-cols-3 gap-4">
				<CardTop title="Top products" data={data.products} />
				<CardTop title="Top countries" data={data.countries} />
				<CardTop title="Top colours" data={data.swatches} />
				<CardTop title="Top wishlist" data={data.bookmarks} />
				<CardTop title="Top customers" data={data.customers} />
				<CardTop title="Top payments" data={data.payments} />
			</div>
		</div>
	);
};

const Stats: React.FC = () => {
	const data = useLoaderData() as { result: any };
	const submit = useSubmit();
	return (
		<Suspense>
			<DateRange
				className="overflow-hidden rounded shadow"
				onChange={([from, to]) => {
					const data = new FormData();
					data.append(
						"from",
						formatISO(from, {
							representation: "date",
						}),
					);
					data.append(
						"to",
						formatISO(to, {
							representation: "date",
						}),
					);
					submit(data, { replace: true });
				}}
			/>
			<Await resolve={data.result} children={<Cards />} />
		</Suspense>
	);
};

export default Stats;
