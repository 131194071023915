import Vue from "vue";
import countryData from "../../assets/countries.json";

export type Filter =
	| "day"
	| "week"
	| "month"
	| "year"
	| "custom"
	| "customer-name"
	| "customer-email"
	| "order-number";

interface ComponentData {
	filter: Filter;
	filterLabel?: string;
	filterType?: "string" | "date";
	valueDate: { start?: Date; end?: Date };
	valueString?: string;
	valueCountry?: string;
	countryList: Array<{ label: string; value: string }>;
}

let valueStringTimeout: ReturnType<typeof setTimeout>;

interface Component extends ComponentData, Vue {}

function isSameDate(start?: Date, end?: Date): boolean {
	if (start !== undefined && end !== undefined) {
		return start.valueOf() === end.valueOf();
	}

	return false;
}

function getStartDateForFilter(filter: Filter): Date {
	const now = new Date();
	/*tslint:disable no-magic-numbers */
	now.setUTCHours(0);
	now.setUTCMinutes(0);
	now.setUTCSeconds(0);
	now.setUTCMilliseconds(0);

	if (filter === "week") {
		now.setUTCDate(now.getUTCDate() - 7);
	}
	if (filter === "month") {
		now.setUTCDate(1);
	}
	if (filter === "year") {
		now.setUTCDate(1);
		now.setUTCMonth(0);
	}
	/*tslint:enable no-magic-numbers */

	return now;
}
function getEndDateForStartDate(date: Date): Date {
	/*tslint:disable no-magic-numbers */
	const end = new Date(date);
	end.setUTCHours(24);
	end.setUTCMinutes(0);
	end.setUTCSeconds(0);
	end.setUTCMilliseconds(-1);
	/*tslint:enable no-magic-numbers */

	return end;
}

function onValueStringInput(this: Component): void {
	const timer = 5e2;
	clearTimeout(valueStringTimeout);
	valueStringTimeout = setTimeout(onFilterChange.bind(this), timer);
}

function onFilterChange(this: Component): void {
	let rules = [];
	if (
		this.filterType === "date" &&
		this.valueDate.start !== undefined &&
		this.valueDate.end !== undefined
	) {
		rules.push(["_index.createdAt", ">=", this.valueDate.start]);
		rules.push(["_index.createdAt", "<", this.valueDate.end]);
	}

	if (this.filterType === "string" && this.valueString !== undefined) {
		const value = this.valueString
			.trim()
			.toLowerCase()
			.replace(/[ @.]+/g, " ");

		switch (this.filter) {
			case "customer-name":
				rules.push(["_index.customer.name", "array-contains", value]);
				break;
			case "customer-email":
				rules.push(["_index.customer.email", "array-contains", value]);
				break;
			case "order-number":
				rules.push(["ref", "==", parseInt(value)]);
				break;
			default:
		}
	}

	if (this.valueCountry !== "ALL") {
		let value = this.valueCountry;
		if (value === "EFTA-EEA") {
			rules.push(["payment.currency", "==", "eur"]);
		} else {
			rules.push(["customer.address.country", "==", value]);
		}
	}

	this.$emit("change", rules, this.filter);
}

export default Vue.component("retail-order-list-filter", {
	computed: {
		filterLabel(this: Component): string {
			return this.filter.replace(/-/g, " ");
		},
		filterType(this: Component): string {
			let value;
			switch (this.filter) {
				case "day":
				case "week":
				case "month":
				case "year":
				case "custom":
					value = "date";
					break;
				default:
					value = "string";
			}

			return value;
		},
	},
	methods: { onFilterChange, onValueStringInput },
	data(): ComponentData {
		return {
			countryList: countryData.map((c) => ({
				label: c.name,
				value: c.code,
			})),
			filter: "week",
			valueDate: { start: undefined, end: undefined },
			valueString: undefined,
			valueCountry: "ALL",
		};
	},
	props: [],
	template: `
	<div class=retail-order-list-filter>
		<div class='retail-order-list-filter__column retail-order-list-filter__column_selector'>
			<label>
				<span>Filter results by</span>
				<select v-model=filter>
					<option value=day>Today</option>
					<option value=week>Last 7 Days</option>
					<option value=month>This Month</option>
					<option value=year>This Year</option>
					<option value=custom>Custom Period</option>
					<option value=customer-name>Customer Name</option>
					<option value=customer-email>Customer Email</option>
					<option value=order-number>Order Number</option>
				</select>
			</label>
		</div>
		<div class='retail-order-list-filter__column retail-order-list-filter__column_value'>
			<template v-if='filterType === "date"'>
				<label class='retail-order-list-filter__value__date retail-order-list-filter__value__date_start'>
					<span>Start</span>
					<input type=date
						:value="valueDate.start && valueDate.start.toISOString().split('T')[0]"
						@input="valueDate.start = $event.target.valueAsDate || undefined">
				</label>
				<label class='retail-order-list-filter__value__date retail-order-list-filter__value__date_end'>
					<span>End</span>
					<input type=date
						:value="valueDate.end && valueDate.end.toISOString().split('T')[0]"
						@input="valueDate.end = $event.target.valueAsDate || undefined">
				</label>
			</template>
			<template v-else>
				<label class=retail-order-list-filter__value__string>
					<span>{{filterLabel}}</span>
					<input v-model=valueString @input=onValueStringInput>
				</label>
			</template>
			<label class=retail-order-list-filter__country>
				<span>Country</span>
				<select v-model=valueCountry @change=onFilterChange>
					<option value='ALL'>All Countries</option>
					<option>EFTA-EEA</option>
					<option v-for='country in countryList' :key=country.value :value=country.value>
						{{country.label}}
					</option>
				</select>
			</label>
		</div>
	</div>
	`,
	watch: {
		filter: {
			handler(this: Component, filter: Filter): void {
				if (["day", "week", "month", "year"].indexOf(filter) !== -1) {
					this.valueDate.start = getStartDateForFilter(filter);
					this.valueDate.end = getEndDateForStartDate(new Date());
				}
				if (this.filterType === "string") {
					this.valueString = undefined;
				}
			},
			immediate: true,
		},
		valueDate: {
			handler(this: Component, v: ComponentData["valueDate"]): void {
				if (
					v.end !== undefined &&
					v.start !== undefined &&
					v.start > v.end
				) {
					v.end = undefined;
				}
				const isSameStart = isSameDate(
					v.start,
					getStartDateForFilter(this.filter),
				);
				const isSameEnd = isSameDate(
					v.end,
					getEndDateForStartDate(new Date()),
				);
				if (!isSameStart || !isSameEnd) {
					this.filter = "custom";
				}
				if (v.start !== v.end) {
					onFilterChange.call(this);
				}
			},
			deep: true,
			immediate: true,
		},
	},
});
