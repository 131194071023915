import { firestore } from "./firebase";
import { log } from "./utils";

import type firebase from "firebase";

const db = firestore();

/*type DocumentData = firebase.firestore.DocumentData;*/
type DocumentChange = firebase.firestore.DocumentChange;
type DocumentChangeType = firebase.firestore.DocumentChangeType;
type QuerySnapshot = firebase.firestore.QuerySnapshot;
type Query = firebase.firestore.Query;
type FieldPath = firebase.firestore.FieldPath;
type WhereFilterOp = firebase.firestore.WhereFilterOp;
type QueryValue = Date | string | number | boolean;

export type DocChangeBatchCallback = (changes: DocChangeBatchResult[]) => void;
export type DocChangeCallback = (
	changeType: DocumentChangeType,
	doc: firebase.firestore.DocumentData,
) => void;

export interface DocChangeBatchResult {
	changeType: DocumentChangeType;
	doc: firebase.firestore.DocumentData;
}

export type QueryFilter = [FieldPath | string, WhereFilterOp, QueryValue];

interface WatchOptions {
	collection: string;
	where?: QueryFilter[];
	orderBy?: [FieldPath | string, "asc" | "desc" | undefined];
	onDoc?: DocChangeCallback;
	onBatch?: DocChangeBatchCallback;
}

export function watch(options: WatchOptions): () => void {
	let ref: Query = db.collection(options.collection);
	if (options.where !== undefined) {
		options.where.forEach((filter: QueryFilter) => {
			ref = ref.where(...filter);
		});
	}
	if (options.orderBy !== undefined) {
		ref = ref.orderBy(options.orderBy[0], options.orderBy[1]);
	}

	return ref.onSnapshot((snapshot: QuerySnapshot) => {
		const docs: DocChangeBatchResult[] = [];
		snapshot.docChanges().forEach((change: DocumentChange) => {
			const doc = change.doc.data();
			doc.id = change.doc.id;
			docs.push({ changeType: change.type, doc });
			if (options.onDoc !== undefined) {
				options.onDoc(change.type, doc);
			}
		});
		if (options.onBatch !== undefined) {
			options.onBatch(docs);
		}
	}, log.error);
}
