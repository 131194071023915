import phoneModels from "../../assets/phone-models.json";
import tabletModels from "../../assets/tablet-models.json";

import { fmtOrderRef, getOrderNotes, toDate } from "@launerlondon/shared";

import type {
	Item as RetailOrderItem,
	ItemOption as RetailOrderItemOption,
	RetailOrder,
} from "@launerlondon/shared";

const fmtOptionValue = (option: RetailOrderItemOption): string => {
	let source;
	if (option.name.match(/phone model/i)) {
		source = phoneModels;
	}
	if (option.name.match(/tablet model/i)) {
		source = tabletModels;
	}
	if (source) {
		const model = source.find((o) => o.label === option.value);
		return `${model?.label} (${model?.size})`;
	}
	return option.value;
};

const orderPrint = (order: RetailOrder): void => {
	const mywindow = window.open("", "PRINT", "height=400,width=600");

	const getOptions = (option: RetailOrderItemOption): string => {
		return `
		<tr class=retail-order-summary__item__attr>
			<th class=retail-order-summary__item__key>${option.name}</th>
			<td class=retail-order-summary__item__value>${fmtOptionValue(option)}</td>
		</tr>
		`;
	};

	const getProducts = (product: RetailOrderItem): string =>
		`
		<tbody>
			<tr>
				<th>Product</th>
				<td>${product.name}</td>
			</tr>
			<tr>
				<th>SKU</th>
				<td>${product.sku}</td>
			</tr>
			${product.options?.map(getOptions).join("")}
		</tbody>
	`;

	const orderDate = toDate(order.events[0].date).toLocaleDateString();
	const notes = getOrderNotes(order);
	const orderNotes = notes.factory
		? `
		<p>
			<b>Order notes:</b>
			<span>${notes.factory}</span>
		</p>
		`
		: "";

	mywindow?.document.write(`
		<title></title>
		<style>
			body {
				max-width: 200mm;
				margin: auto;
				font: 10pt sans-serif;
			}
			table {
				font-size: inherit;
				width: 100%;
				border-collapse: collapse;
			}
			tbody {
				display: table;
				width: 100%;
				margin-bottom: 30pt;
			}
			tr { border-bottom: 1px solid black; }
			th { width: 40%; text-align: left; }
			td { padding: 5pt; }
		</style>
		<div class='order-print-out'>
			<h1>Launer London</h1>
			<h2>Order #${fmtOrderRef(order.ref)}</h2>
			<p><small>Order placed ${orderDate}</small></p>
			${orderNotes}
			<table>
				${order.items.map(getProducts).join("")}
			</table>
		</div>
	`);

	mywindow?.document.close(); // necessary for IE >= 10
	mywindow?.focus(); // necessary for IE >= 10*/
	mywindow?.print();
	//mywindow?.close();
};

export default orderPrint;
