import {
	AdminAppLayout,
	AdminStock,
	AppLayout,
	RetailOrderView,
	Stats,
	VoucherList,
	VoucherView,
} from "@launerlondon/orders-screens";
import {
	createBrowserRouter,
	createRoutesFromChildren,
	Route,
	RouterProvider,
} from "react-router-dom";
import {
	OrderAction,
	StockAction,
	VoucherAction,
	VouchersAction,
} from "./actions";
import {
	AppLoader,
	OrderLoader,
	StatsLoader,
	StockLoader,
	VoucherLoader,
	VouchersLoader,
} from "./loaders";

export const AppRouter: React.FC = () => {
	return (
		<RouterProvider
			router={createBrowserRouter(
				createRoutesFromChildren(
					<Route id="app" loader={AppLoader} element={<AppLayout />}>
						<Route
							path="/retail/orders/:id/:ref"
							loader={OrderLoader}
							action={OrderAction}
							element={<RetailOrderView />}
						/>
						<Route
							path="/accounts/:account/orders/:id"
							loader={OrderLoader}
							action={OrderAction}
							element={<RetailOrderView />}
						/>
						<Route
							path="/vouchers"
							loader={VouchersLoader}
							action={VouchersAction}
							element={<VoucherList />}
						>
							<Route
								path=":id"
								loader={VoucherLoader}
								action={VoucherAction}
								element={<VoucherView />}
							/>
						</Route>

						<Route path="/cms" element={<AdminAppLayout />}>
							<Route
								path="stock"
								loader={StockLoader}
								action={StockAction}
								element={<AdminStock />}
							/>
							<Route path="*" element={<h1>Not Found</h1>} />
						</Route>
						<Route
							path="/stats"
							loader={StatsLoader}
							element={<Stats />}
						/>
					</Route>,
				),
			)}
		/>
	);
};
