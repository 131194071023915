import { generate } from "@pdfme/generator";
import basePdf from "../assets/label-base.pdf?url";
export default async function (content: string) {
	const pdf = await generate({
		template: {
			schemas: [
				{
					content: {
						type: "text",
						position: { x: 50.5, y: 26 },
						width: 60,
						height: 43,
						alignment: "left",
						fontSize: 12,
						characterSpacing: 0,
						lineHeight: 1.4,
					},
				},
			],
			basePdf,
		},
		inputs: [{ content }],
	});
	const blob = new Blob([pdf.buffer], { type: "application/pdf" });
	return URL.createObjectURL(blob);
}
