import { AccountAddress } from "./wholesaleAccount";

export interface OrderItemOption {
	name: string;
	value?: string;
}

export interface OrderItemStatus {
	cut?: Date;
	prepare?: Date;
	complete?: Date;
}

export interface OrderItem {
	sku: string;
	name: string;
	options?: OrderItemOption[];
	price: number;
	status: OrderItemStatus;
}

export interface OrderMeta {
	createdAt?: Date;
	lastUpdated?: Date;
	lastPushed?: Date;
	orderTotal?: number;
	lastAccountCheck?: string;
}

export interface Order {
	id: string;
	reference: string;
	_meta: OrderMeta;
	accountId: string;
	date: Date;
	dueDate?: Date;
	completionDate?: Date;
	shippingDate?: Date;
	address: AccountAddress;
	items: OrderItem[];
	notes?: string;
}

export function isCompleteOrder(order: Order): boolean {
	if (order.items.length === 0) {
		return false;
	}
	const o = order.items.find((item: OrderItem): boolean => {
		const hasCompleted = "complete" in item.status;

		return !hasCompleted;
	});

	return o === undefined;
}

export function orderTotal(order: Order): number {
	return order.items.reduce(
		(p: number, c: OrderItem): number => p + c.price,
		0,
	);
}

export function orderTotalItemsStep(step: string, order: Order): number {
	const items = order.items.filter((item: OrderItem): boolean => {
		const hasStep = item.status.hasOwnProperty(step);

		return hasStep;
	});

	return items.length;
}

export function ordersTotal(orders: Order[]): number {
	return orders.reduce((p: number, c: Order): number => p + orderTotal(c), 0);
}

export function getOrderLastCompletedItemDate(order: Order): Date | undefined {
	return order.items
		.map((item: OrderItem): Date | undefined => item.status.complete)
		.sort()
		.pop();
}

function getOrderOptionHeaders(orderItems: OrderItem[]): string[] {
	const headers: string[] = [];
	orderItems.forEach((orderItem: OrderItem) => {
		if (orderItem.options !== undefined) {
			orderItem.options.forEach((orderItemOption: OrderItemOption) => {
				if (headers.indexOf(orderItemOption.name) === -1) {
					headers.push(orderItemOption.name);
				}
			});
		}
	});

	return headers;
}

export function convertOrderToCSV(order: Order): string {
	const headers = [
		"Customer",
		"PO #",
		"Date",
		"Product",
		"Code",
		...getOrderOptionHeaders(order.items),
		"Expected Delivery",
		"Notes",
		"Cost",
	];

	const rows = order.items.map((orderItem: OrderItem): string[] => {
		const row = Array(headers.length).fill("");
		row[headers.indexOf("Customer")] = order.accountId;
		row[headers.indexOf("PO #")] = order.id;
		row[headers.indexOf("Date")] = order.date.toLocaleDateString();
		row[headers.indexOf("Product")] = orderItem.name;
		row[headers.indexOf("Code")] = orderItem.sku;
		row[headers.indexOf("Expected Delivery")] =
			order.dueDate?.toLocaleDateString();
		row[headers.indexOf("Notes")] = order.notes;
		row[headers.indexOf("Cost")] = orderItem.price;
		if (orderItem.options !== undefined) {
			orderItem.options.forEach((orderItemOption: OrderItemOption) => {
				row[headers.indexOf(orderItemOption.name)] =
					orderItemOption.value;
			});
		}

		return row;
	});

	return `${headers}\n${rows.join("\n")}`;
}
