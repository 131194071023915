import {
	Item,
	RetailOrder,
	calculateTaxRate,
	defaultRoundFactor,
	fmtOrderRef,
	getItemOptionTotal,
	getOrderLastEvent,
	getOrderTotal,
	getTotalTax,
	mergeAmounts,
	toDate,
} from "@launerlondon/shared";
import { getPrice } from "@launerlondon/products";

function getOrderWholesaleOptionsTotal(order: RetailOrder) {
	return order.items.reduce((p, c) => {
		const totalOptions = c.options?.reduce((p1, c1) => {
			if (c1.name === "Gold plated corners") {
				if (c1.value === "One pair") return p1 + getPrice("0002-01")[1];
				if (c1.value === "Two pairs")
					return p1 + getPrice("0002-02")[1];
			}
			if (c1.name === "Lettering") {
				return c1.value.length < 10
					? p1 + getPrice("0003-01")[1]
					: p1 + getPrice("0003-02")[1];
			}
			if (c1.name === "Faraday shield") {
				return p1 + getPrice("0004-01")[1];
			}
			if (c1.name === "Emblem") {
				if (c1.value === "crystal") {
					return p1 + getPrice("0005-01")[1];
				}
			}
			return p1;
		}, 0);
		return p + (totalOptions || 0);
	}, 0);
}

function getOrderWholesaleItemsTotal(order: RetailOrder) {
	return order.items.reduce((p, c) => {
		const [_retail, wholesale] = getPrice(c.sku);
		return p + wholesale;
	}, 0);
}

export default function convertOrdersToCSV(orders: RetailOrder[]) {
	const SEPARATOR = ",";
	const headers = [
		"Ref",
		"Description",
		"Date",
		"Name",
		"Telephone",
		"Email",
		"Details",
		"Order notes",
		"Extras",
		"Display Ccy",
		"Paid Ccy",
		"Shipping Country",
		"Local tax rate",
		"Subtotal (GBP)",
		"Total (GBP)",
		"Wholesale (GBP)",
		"Total Paid (CCY)",
		"Status",
	].join(SEPARATOR);

	const rows = orders
		.filter((order) => order.events.some((e) => e.type === "charged"))
		.map((order) => {
			const total = getOrderTotal(order, order._index.roundFactor);
			const { country, state } = order.customer.address;
			const vat = getTotalTax(order.total, country, state);
			const event = getOrderLastEvent(order);

			const row = [
				fmtOrderRef(order.ref),
				"Order info",
				toDate(order.events[0].date).toLocaleDateString(),
				order.customer.name,
				order.customer.phone,
				order.customer.email,
				`\"${order.items
					.map((item: Item): string => `${item.sku} ${item.name}`)
					.join(" | ")}\"`,
				`\"${order.events[0].message?.replace(/\W+/g, " ")}\"`,
				"",
				order.customer.currency.toUpperCase(),
				order.payment.currency.toUpperCase(),
				order.customer.address.country,
				calculateTaxRate(total, country) + "%",
				order.customer.address.country === "GB"
					? total.subtotal.gbp?.toFixed(2) || 0
					: total.subtotal.base?.toFixed(2) || 0,
				total.gross.gbp?.toFixed(2) || 0,
				getOrderWholesaleItemsTotal(order),
				`${total.gross[order.payment.currency]?.toFixed(
					2,
				)} ${order.payment.currency.toUpperCase()}`,
				event.type,
			].join(SEPARATOR);

			const taxline = [
				"",
				"Duties & taxes",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				vat.gbp?.toFixed(2),
				"",
				"",
				"",
				"",
			].join(SEPARATOR);

			const shippingLine = [
				"",
				"Shipping",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				total.shipping.gbp?.toFixed(2),
				"",
				"",
				"",
				"",
			].join(SEPARATOR);

			const chargedExtras = order.items.flatMap((i) => {
				return i.options
					?.filter((o) => o.multiplier > 0)
					.map((o) => o.name);
			});
			const extraNames = chargedExtras.reduce<string[]>((p, c) => {
				if (c && !p.includes(c)) p.push(c);
				return p;
			}, []);
			const extraAmount = mergeAmounts(
				order.items.flatMap((i) => {
					return (
						i.options?.map((o) =>
							getItemOptionTotal(o, i.price, defaultRoundFactor),
						) || []
					);
				}),
			);

			const extrasLine = [
				"",
				"Extras",
				"",
				"",
				"",
				"",
				"",
				"",
				`\"${extraNames.join(",")}\"`,
				"",
				"",
				"",
				"",
				extraAmount.gbp?.toFixed(2),
				"",
				getOrderWholesaleOptionsTotal(order),
				"",
				"",
			].join(SEPARATOR);

			const breakLine = Array(18).fill("").join(SEPARATOR);

			return [row, taxline, shippingLine, extrasLine, breakLine].join(
				"\n",
			);
		});

	rows.splice(0, 0, headers);
	return `data:text/csv;charset=utf-8,${rows.join("\n")}`;
}
